import {toast, Bounce} from "react-toastify";

export function bottomAlert(message, message_type = "success", onClick, chime, chime_file) {
  if (chime) {
    if (chime_file) {
      var audio = new Audio(chime_file);
      audio.play();
    } else {
      var audio = new Audio('/uploads/sounds/Store_Door_Chime.mp3');
      audio.play();
    }
  }
  toast(message, {
    transition: Bounce,
    closeButton: true,
    autoClose: 5000,
    position: "bottom-right",
    type: message_type,
    onclick: onClick
  });
}

export function topAlert(message, message_type = "success", onClick, chime, chime_file) {
  if (chime) {
    if (chime_file) {
      var audio = new Audio(chime_file);
      audio.play();
    } else {
      var audio = new Audio('/uploads/sounds/Store_Door_Chime.mp3');
      audio.play();
    }
  }
  toast(message, {
    transition: Bounce,
    closeButton: true,
    autoClose: 5000,
    position: "top-right",
    type: message_type,
    onclick: onClick
  });
}

export function topCenterAlert(message, message_type = "success", onClick, chime, chime_file) {
  if (chime) {
    if (chime_file) {
      var audio = new Audio(chime_file);
      audio.play();
    } else {
      var audio = new Audio('/uploads/sounds/Store_Door_Chime.mp3');
      audio.play();
    }
  }
  toast(message, {
    transition: Bounce,
    closeButton: true,
    autoClose: 5000,
    position: "top-center",
    type: message_type,
    onclick: onClick
  });
}

export function soundAlert(sound_file) {
  if (sound_file) {
    var audio = new Audio(sound_file);
    audio.play();
  } else {
    var audio = new Audio('/uploads/sounds/Store_Door_Chime.mp3');
    audio.play();
  }
}