import React, { Fragment } from "react";
import { withRouter } from "react-router";

import PerfectScrollbar from "react-perfect-scrollbar";

import {
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Nav,
  NavItem,
} from "reactstrap";
import { Divider, Tooltip } from "@material-ui/core";

import { toast, Bounce } from "react-toastify";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import city3 from "../../../assets/utils/images/dropdown-header/city3.jpg";
import user_image from "../../../assets/utils/images/avatars/user_icon.png";
import { app_cleanup, getUser, logout } from "../../../Helpers/User";
import { getClient } from "../../../Helpers/Client";
import { config } from "../../../config";
import { auth } from "../../../Auth/Firebase";
import { hasCookie } from "../../../Helpers/Cookie";
import { bottomAlert } from "../../../Components/Notifications/ToastAlerts";
import ShowContent from "../../../Components/Modal/ShowContent";
import { getCall, postCall } from "../../../Helpers/Api";
import { notificationsHandle } from "../../../States/Connections";
import { Link } from "react-router-dom";
import { BsFillPersonFill } from "react-icons/bs";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoSettingsSharp } from "react-icons/io5";
import Notifications from "./TabsContent/Notifications";
import { MdOutlineAdminPanelSettings, MdPassword, MdHelpOutline, MdOutlineEmail, MdOutlineLogout } from "react-icons/md"
import { AiOutlineApi } from "react-icons/ai"
import { SiKnowledgebase } from "react-icons/si"
import { noDelaySetInterval } from "../../../Helpers/Time";

class UserBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: getUser(),
      client: getClient(),
      allowed_clients: [],
      count: 0,
      notifications: [],
      organizationName: "",
      current_organizationName: "",
      modalOpen: false, // For controlling modal visibility
    };

    this.logout = this.logout.bind(this);
    this.getYourAllOrg = this.getYourAllOrg.bind(this);
    this.toggleOrgForm = this.toggleOrgForm.bind(this);
    this.handlerOrganization = this.handlerOrganization.bind(this);
    this.getUnreadNotifications = this.getUnreadNotifications.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  handlerOrganization = (e) => {
    this.setState({ organizationName: e.target.value });

    if (e.target.value.length >= 1) {
      this.setState({ error: true });
    }
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this.setState({ error: false });
    }
    if (e.target.value.length > 3) {
      this.setState({ disableButton: false });
    } else {
      this.setState({ disableButton: true });
    }
  };

  toggleOrgForm = () => {
    this.setState({
      orgForm: !this.state.orgForm,
    });
  };

  getYourAllOrg = () => {
    getCall({
      url: config.url.CLIENTS,
      path: `/allowed_clients`,
      onSuccess: (response) => {
        const sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name));
        this.setState({ allowed_clients: sortedData });
      },
      onFailure: (error) => {
        bottomAlert(
          "We are unable to fetch your organizations. " +
          ((error.response && error.response.data.detail) ||
            error.toString()),
          "warning"
        );
      },
    });
  };

  createNewOrg = (e) => {
    postCall({
      url: config.url.CLIENTS,
      path: "/create_and_map/" + this.state.organizationName,
      onSuccess: (response) => {
        this.state.organizationName = "";
        this.getYourAllOrg();
        bottomAlert("New organization created successfully.", "success");
        this.toggleOrgForm();
      },
      onFailure: (error) => {
        bottomAlert(
          "We are unable to create new organization. " +
          ((error.response && error.response.data.detail) ||
            error.toString()),
          "warning"
        );
      },
    });
  };

  switch_account = (identifier) => {
    notificationsHandle.close();
    postCall({
      url: config.url.USERS,
      path: `/switch_client/${identifier}`,
      onSuccess: (response) => {
        getCall({
          url: config.url.USERS,
          path: getUser().id,
          onSuccess: (response) => {
            console.log(response.data);
          },
        });
        app_cleanup();
        bottomAlert("Let's get you to the selected client.", "success");
        window.location.reload();
      },
      onFailure: (error) => {
        bottomAlert(
          "We are Unable to switch the client. " +
          ((error.response && error.response.data.detail) ||
            error.toString()),
          "warning"
        );
      },
    });
  };

  getUnreadNotifications() {
    getCall({
      url: config.url.NOTIFICATIONS,
      path: "/unread_notification",
      onSuccess: (response) => {
        this.setState({ count: response.data.count });
        this.setState({ notifications: response.data.records });
      },
      onFailure: (error) => {
        console.log(error);
      },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    // Automatically open modal when there are notifications and modal is not open
    if (this.state.count > 0 && !this.state.modalOpen) {
      this.setState({ modalOpen: true });
    }

    // Automatically close modal when the count becomes 0
    if (prevState.count > 0 && this.state.count === 0 && this.state.modalOpen) {
      this.setState({ modalOpen: false });
    }
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };
  componentDidMount() {
    this.setState({
      user: getUser(),
      client: getClient(),
    });
    this.getYourAllOrg();
    this.timer = noDelaySetInterval(this.getUnreadNotifications, 4000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  notify2 = () =>
  (this.toastId = toast(
    "You don't have any new items in your calendar for today! Go out and play!",
    {
      transition: Bounce,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-center",
      type: "success",
    }
  ));

  logout = () => {
    logout();
  };

  changePassword() {
    auth
      .sendPasswordResetEmail(this.state.user.email)
      .then(() => {
        this.setState({ email_sent: true });
      })
      .catch((error) => {
        bottomAlert(
          "Request failed. " +
          ((error.response && error.response.data.detail) ||
            error.toString()),
          "warning"
        );
      });
  }

  render() {
    let profile_image = user_image;
    var screen_width = window.matchMedia("(max-width:400px)");
    var screen_max_600 = window.matchMedia("(max-width:600px)");
    var screen_max_768 = window.matchMedia("(max-width:768px)");

    if (this.state.user.profile_image) {
      profile_image = this.state.user.profile_image;
    }
    const { count, modalOpen } = this.state;
    return (
      <Fragment>
        {this.state.email_sent && (
          <ShowContent
            content={
              "We have sent you an email at " +
              this.state.user.email +
              " to change your password. Please follow the instructions in the email."
            }
            toggle={(show) => {
              this.setState({ email_sent: show });
            }}
            title={"Instructions sent"}
            buttonText={"OK"}
          />
        )}
        <div
          className="header-btn-lg"
          style={{ gap: screen_width.matches ? 8 : screen_max_768.matches ? 15 : 20, padding: "0 0 0 7px", marginLeft: 7 }}
        >
          {/* <div className="header-btn-lg pr-0 mt-2 pl-2"> */}
          {/* <div className="widget-content p-0">
            <div className="widget-content-wrapper"> */}
          {/* <div className="widget-content-left"> */}

          {/* notification */}
          <UncontrolledDropdown style={{ marginTop: 5 }}>
            <Tooltip title="Notifications">
              <DropdownToggle className="p-0" color="link">
                <div
                  // className="icon-wrapper icon-wrapper-alt"
                  id="show_notifications"
                >
                  <div
                    className={
                      this.state.count > 0 ? "bg-danger" : "bg-secondary"
                    }
                  />
                  <IoIosNotificationsOutline
                    className={
                      this.state.count > 0 ? "text-danger" : "text-secondary"
                    }
                    color="#d92550"
                    fontSize={screen_max_600.matches ? "20px" : "25px"}
                    fontWeight={600}
                  ></IoIosNotificationsOutline>
                  <span
                    className={
                      "Notifications rounded-circle " +
                      (this.state.count > 0 ? "bg-danger" : "bg-secondary")
                    }
                    style={{
                      position: "absolute",
                      top: "-4px",
                      border: "1px solid #fff",
                      borderRadius: "50%",
                      right: "-4px",
                      fontSize: screen_max_600.matches ? "8px" : "10px",
                      background: "#f44336",
                      display: "flex",
                      placeContent: "center",
                      placeItems: "center",
                      color: "white",
                      padding: "0px",
                      minHeight: screen_max_600.matches ? "16px" : "18px",
                      minWidth: screen_max_600.matches ? "16px" : "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.count}
                  </span>
                </div>
                {/* <label style={{display: "block", fontSize: "10px"}}>Alerts</label> */}
              </DropdownToggle>
            </Tooltip>
            <DropdownMenu
              right={this.props.alignNotificationsToRight}
              className="dropdown-menu-xl rm-pointers"
            >
              <div className="dropdown-menu-header mb-0">
                <div className="dropdown-menu-header-inner bg-deep-blue">
                  <div
                    className="menu-header-image opacity-1"
                    style={{
                      backgroundImage: "url(" + ")",
                    }}
                  />
                  <div className="menu-header-content text-dark">
                    <h5 className="menu-header-title">Notifications</h5>
                    <h6 className="menu-header-subtitle">
                      You have <b>{this.state.count}</b> unread messages
                    </h6>
                  </div>
                </div>
              </div>
              <Notifications notifications={this.state.notifications} />
              {/* {console.log(this.state.notifications)} */}
              <Nav vertical>
                <NavItem className="nav-item-divider" />
                <NavItem className="nav-item-btn text-center">
                  <Link to="/history/notifications">
                    <Button
                      size="sm"
                      className="btn-shadow btn-wide btn-pill"
                      color="focus"
                    >
                      View all notifications
                    </Button>
                  </Link>
                </NavItem>
              </Nav>
            </DropdownMenu>
          </UncontrolledDropdown>
          {/* Modal that opens when there are notifications */}
          <Modal isOpen={modalOpen} toggle={this.toggleModal} >
            <ModalHeader>Notifications</ModalHeader> {/* Close button removed */}
            <ModalBody>
              <Notifications notifications={this.state.notifications} />
              {/* <Link to="/history/notifications">
      <Button
        size="sm"
        className="btn-shadow btn-wide btn-pill"
        color="focus"
        onClick={() => {
          this.toggleModal(); // This will close the modal
        }}
      >
        View all notifications
      </Button>
    </Link> */}
            </ModalBody>
          </Modal>

          {
            this.props.showSettings &&
            <Link to={"/settings"}>
              <IoSettingsSharp style={{ fontSize: screen_max_600.matches ? 20 : 24, cursor: "pointer" }} className="text-secondary" />
            </Link>
          }
          {getClient().sign_source !== "saleassist" ? (
            <></>
          ) : (
            <UncontrolledDropdown>
              <Tooltip title="Profile and help">
                <DropdownToggle
                  color="link"
                  data-tut="reactour__organizationSettings"
                  className="p-0"
                  id="show_settings"
                >
                  {/* <i
                  style={{
                    fontSize: "20px",
                  }}
                  className="lnr-cog icon-gradient bg-secondary"
                ></i> */}
                  <BsFillPersonFill
                    style={{ fontSize: screen_max_600.matches ? 20 : 24 }}
                    className="text-secondary"
                  />
                </DropdownToggle>
              </Tooltip>
              <DropdownMenu
                right={this.props.alignProfileToRight}
                className="rm-pointers dropdown-menu-lg"
                style={{ minWidth: 280, padding: 0 }}
              >
                {/* <div className="dropdown-menu-header"> */}
                {/* <div className="dropdown-menu-header-inner bg-info">
                  <div
                    className="menu-header-image opacity-2"
                    style={{
                      backgroundImage: "url(" + city3 + ")",
                    }}
                  />
                  <div className="menu-header-content text-left">
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left mr-3">
                          <img
                            width={42}
                            className="rounded-circle"
                            src={profile_image}
                            alt=""
                          />
                        </div>
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            {this.state.user.display_name}
                          </div>
                          <div
                            className="widget-subheading opacity-8"
                            style={{ width: "180px", wordBreak: "break-all" }}
                          >
                            {this.state.user.user_name}
                          </div>
                        </div>
                        {!hasCookie("saleassist_disable_logout") && (
                          <div className="widget-content-right mr-2">
                            <Button
                              className="btn-pill btn-shadow btn-shine"
                              color="focus"
                              onClick={() => this.logout()}
                            >
                              Logout
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                <div style={{ padding: 10, display: "flex", flexDirection: "column", placeContent: "center", placeItems: "center", background: "#f2f2f2", position: "relative" }}>
                  <div style={{ fontSize: 16, fontWeight: "bold", color: "#000000ab" }}>{this.state.user.display_name}</div>
                  <div style={{ fontSize: 14, fontWeight: "bold", color: "#787878", wordBreak: "break-word", textAlign: "center" }}>{this.state.user.user_name}</div>
                  <div style={{ width: 16, height: 16, position: "absolute", bottom: -8, transform: "rotate(45deg)", background: "#f2f2f2" }}></div>
                </div>
                <div
                  className="scroll-area-xs"
                  style={{
                    height: "auto",
                    padding: "10px",
                  }}
                >
                  <PerfectScrollbar style={{ display: "flex", flexDirection: "column" }}>
                    {/* <a style={{margin: "6px", display: "block"}} href="#" onClick={(e) => {e.preventDefault(); this.props.history.push('/settings/user/profile')}}>Profile</a> */}
                    {/* <a style={{margin: "6px", display: "block"}} href={config.url.BASE + '/redoc'} target="_blank">API documentation</a> */}
                    {["chetan@saleassist.ai", "deep@saleassist.ai", "satyam@saleassist.ai"].includes(this.state.user.email) && (
                      <Link
                        to={"/admin"}
                        style={{ margin: "6px", display: "flex" }}
                      >
                        <MdOutlineAdminPanelSettings
                          style={{
                            fontSize: "18px",
                            marginRight: "6px",
                            cursor: "pointer",
                          }}
                        />
                        Super admin
                      </Link>
                    )}

                    <a
                      style={{ margin: "6px", display: "block" }}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.changePassword();
                      }}
                    >
                      <MdPassword
                        style={{
                          fontSize: "18px",
                          marginRight: "6px",
                          cursor: "pointer",
                        }}
                      />
                      Change password
                    </a>
                    <div style={{ margin: 6, display: 'flex' }}>
                      <i
                        style={{ fontSize: "18px", marginRight: 6 }}
                        className="lnr-apartment icon-gradient bg-primary"
                      />
                      <Tooltip title="Switch active organisation">
                        <select
                          style={{
                            position: "relative",
                            // top: "-2px",
                            border: "none",
                            cursor: "pointer",
                            background: "#fff"
                          }}
                          className="text-primary"
                          onChange={(e) => this.switch_account(e.target.value)}
                          value={this.state.user.active_client_id}
                          onClick={() => this.getYourAllOrg()}
                        >
                          {this.state.allowed_clients.length > 0 &&
                            this.state.allowed_clients.map((item) => {
                              return (
                                item.is_enabled === "True" ? (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ) : null
                              );
                            })}
                        </select>
                      </Tooltip>
                      <Tooltip title="Add organisation">
                        <i
                          style={{
                            fontSize: "18px",
                            marginLeft: "6px",
                            cursor: "pointer",
                            display: "none"
                          }}
                          className="lnr-plus-circle icon-gradient bg-success"
                          onClick={this.toggleOrgForm}
                        />
                      </Tooltip>
                    </div>
                    <div style={{ position: "relative", margin: "8px 0" }}>
                      <Divider />
                      <span style={{ display: "block", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", background: "#fff", padding: "0 4px", fontSize: 11, fontWeight: "bold", color: "rgba(0,0,0,0.4)" }}>
                        Support
                      </span>
                    </div>
                    {/* For support mail us at{" "} */}
                    <a href="mailto: support@saleassist.ai">
                      <span style={{ margin: "8px 6px", display: "block" }}>
                        <MdOutlineEmail
                          style={{
                            fontSize: "18px",
                            marginRight: "6px",
                            cursor: "pointer",
                          }}
                        />
                        support@saleassist.ai
                      </span>
                    </a>
                    <div style={{ position: "relative", margin: "8px 0" }}>
                      <Divider />
                      <span style={{ display: "block", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", background: "#fff", padding: "0 4px", fontSize: 11, fontWeight: "bold", color: "rgba(0,0,0,0.4)" }}>
                        Resources
                      </span>
                    </div>
                    <a
                      style={{ margin: "6px", display: "block" }}
                      href={"https://docs.saleassist.ai/"}
                      target="_blank"
                    >
                      <SiKnowledgebase
                        style={{
                          fontSize: "14px",
                          marginRight: "8px",
                          marginLeft: "2px",
                          cursor: "pointer",
                        }}
                      />
                      Knowledge base
                    </a>
                    <a
                      style={{ margin: "6px", display: "block" }}
                      href={config.url.BASE + "/redoc"}
                      target="_blank"
                    >
                      <AiOutlineApi
                        style={{
                          fontSize: "18px",
                          marginRight: "6px",
                          cursor: "pointer",
                        }}
                      />
                      API documentation
                    </a>
                    <a
                      style={{ margin: "6px", display: "block" }}
                      href={
                        "https://www.youtube.com/watch?v=HudUurDF3V4&list=PLkWxpWnGr13qORZKYp_wfqS832LpX6S15"
                      }
                      target="_blank"
                    >
                      <MdHelpOutline
                        style={{
                          fontSize: "18px",
                          marginRight: "6px",
                          cursor: "pointer",
                        }}
                      />
                      Help videos
                    </a>

                    {/* Convin customization */}
                    {getClient().sign_source !== "saleassist" ? (
                      <></>
                    ) : (
                      <>
                        <div style={{ position: "relative", margin: "6px 0" }}>
                          <Divider />
                        </div>
                        {!hasCookie("saleassist_disable_logout") && (
                          <div
                            style={{ margin: "6px", display: "block", cursor: "pointer", color: "red" }}
                            onClick={() => this.logout()}
                          >
                            <MdOutlineLogout
                              style={{
                                fontSize: "18px",
                                marginRight: "6px",
                              }}
                            />
                            Logout
                          </div>
                        )}
                      </>
                    )}
                  </PerfectScrollbar>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          {/* </div> */}
          {/* <div className="widget-content-left  ml-3 header-user-info">
            <div className="widget-heading">{this.state.user.display_name}</div>
            <div className="widget-subheading">{this.state.user.user_name}</div>
          </div> */}
          {/* </div>
          </div> */}
        </div>
        <Modal
          isOpen={this.state.orgForm}
          toggle={this.toggleOrgForm}
          className={this.props.className}
        >
          <ModalHeader
            toggle={this.toggleOrgForm}
            close={
              <button className="close" onClick={this.toggleOrgForm}>
                &times;
              </button>
            }
          >
            Add Organization
          </ModalHeader>
          <ModalBody>
            <Label for="referral_email">Organization Name</Label>
            <Input
              type="text"
              name="referral_email"
              id="referral_email"
              value={this.state.organizationName}
              onChange={this.handlerOrganization}
              placeholder="Enter The Name Of The New Organization."
            />
            {this.state.error && (
              <i style={{ color: "red", marginTop: "50px" }}>
                Organization Name is too short
              </i>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggleOrgForm}>
              Cancel
            </Button>
            <Button
              color="success"
              onClick={() => this.createNewOrg()}
              disabled={this.state.disableButton}
            >
              Add
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

UserBox.defaultProps = {
  showSettings: true,
  alignNotificationsToRight: false,
  alignProfileToRight: false,
}

export default withRouter(UserBox);
