import React, { Component, Fragment } from "react";
import Loader from "react-loaders";
import { Col, Row } from "reactstrap";

export default class CenterMessage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const loader = this.props.no_loader ? "" : <Loader color={this.props.loader_color || "#78C3FB"} type={this.props.loader_type || "ball-grid-pulse"} />
    return (
      <Fragment>
        <div className="center-message">
          <div className="loader-container-inner">
            <div className="text-center">
              <Loader type="ball-grid-cy"/>
            </div>
            <h6 className="mt-3">
              {this.props.message || "Getting things ready ..."}
              <small>{this.props.description || "We like to put our wheels in motion for you!"}</small>
            </h6>
            <div className="loader-wrapper d-flex justify-content-center align-items-center">
              {loader}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
};
