export const whatsAppDefaultConfig = {
    "create_meeting_video_call_customer":{
        "action":"create_meeting_video_call_customer",
        "template_config":{
            "template_id": "01hjr8gaxgr2w7zst9rybn2nfs",
            "template_name": "create_meeting_video_call_customer",
            "type": "default"
        },
        "template_body": "\n        Hello there,\n        We at {{1}} started a {{2}} with you.\n        \n        Please join the same.\n        {{3}}\n        \n        Looking forward to connecting with you.\n        \n        Regards,\n        {{4}}\n        Thanks!",
        "notification_user":"customer",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "create_schedule_call_meeting_customer":{
        "action": "create_schedule_call_meeting_customer",
        "template_config": {
            "template_id": "01hsb5dqp8rs2r6hrqj7ehv6p4",
            "template_name": "create_schedule_call_meeting_customer",
            "type": "default" 
        },
        "template_body":"Hi, We at {{1}} scheduled a video call with you on below date and time. Please join the same. Meeting details Scheduled At: {{2}}. {{3}}. Looking forward to connecting with you. Regards, {{4}}. Thanks!",
        "notification_user":"customer",
        "notification_type":"inbound",
        "notification_channel": "whatsapp"
    },
    "create_store_visit_template_customer":    {
        "action": "create_store_visit_template_customer",
        "template_config": {
            "template_id": "01hsb5vnyf5dqje85dsbhbantk",
            "template_name": "create_store_visit_template_customer",
            "type": "default" 
        },
        "template_body":"Hi {{1}} Greetings from {{2}} Your in-store visit is confirmed. Details : Date : {{3}} Time : {{4}} We have informed staff to be ready to check you in time and provide a hassle free shopping experience. Please arrive at least 15 minutes early so that we can get you checked in and ready to start your service. If you have any questions or need to reschedule your appointment, please don't hesitate to contact us. We look forward to seeing you soon! Good day ! Team, {{5}}",
        "notification_user":"customer",
        "notification_type":"inbound",
        "notification_channel": "whatsapp"
    },
    "create_audio_video_call_agent":{
        "action": "create_audio_video_call_agent",
        "template_config": {
            "template_id": "01hsb64mswjmwv345hmb9g28jv",
            "template_name": "create_audio_video_call_agent",
            "type": "default" 
        },
        "template_body":"*Hello*,\n\n{{1}} is waiting for you on live {{2}} call\n\nDetails :\n\nName : {{3}}.\nMobile : {{4}}.\nEmail : {{5}}.\n\nClick on the below link to view all details.\n{{6}}\n\nRegards,\n{{7}}\nThanks!",
        "notification_user":"agent",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "create_store_visit_template_agent":{
        "action": "create_store_visit_template_agent",
        "template_config": {
            "template_id": "01hsb69gbmns8w7gcjraxvbd2g",
            "template_name": "create_store_visit_template_agent",
            "type": "default" 
        },
        "template_body":"Hi\n\nYour meeting schedule with {{1}}.\n\nDate: {{2}}\nTime: {{3}}\n\nDo join on time and keep the collateral ready for the presentation.\nGood day!\nTeam\n{{4}}",
        "notification_user":"agent",
        "notification_type":"inbound",
        "notification_channel": "whatsapp"
    },
    "create_audio_video_call_customer":{
        "action": "create_audio_video_call_customer",
        "template_config": {
            "template_id": "01hkccdqmgrgbxmftnx0zeyag3",
            "template_name": "create_audio_video_call_customer",
            "type": "default" 
        },
        "template_body":"*Hello*,\n\n{{1}} is waiting for you on live {{2}} call\n\nDetails :\n\nName : {{3}}.\nMobile : {{4}}.\nEmail : {{5}}.\n\nClick on the below link to view all details.\n{{6}}\n\nRegards,\n{{7}}\nThanks!",
        "notification_user":"customer",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "create_meeting_video_call_agent":{
        "action":"create_meeting_video_call_agent",
        "template_config":{
            "template_id": "01hsazbq5y8pwqtcxecbytrz33",
            "template_name": "create_meeting_video_call_agent",
            "type": "default"
        },
        "template_body": "*Alert !*\n\nHello,\n\n{{1}} just scheduled a new video meeting with {{2}}.\n\nDetails :\n\nName : {{3}}.\nMobile : {{4}}.\nEmail : {{5}}.\n\nScheduled At: {{6}}.\n\nClick on the below link to view all details.\n{{7}}.\n\nRegards,\n{{8}}.\nThanks!",
        "notification_user":"agent",
        "notification_type":"inbound",
        "notification_channel": "whatsapp"
    },
    "reminder_meeting_schedule_call_agent":{
        "action":"reminder_meeting_schedule_call_agent",
        "template_config":{
            "template_id": "01hsaz8aa9rh55kdhwpcnnvjn7",
            "template_name": "reminder_meeting_schedule_call_agent",
            "type": "default"
        },
        "template_body": "Reminder!\n\n        Gentle Reminder for your upcoming meeting\n        with {{1}} at {{2}}.\n        \n        please join via {{3}}\n        \n        Thanks\n        {{4}}",
        "notification_user":"agent",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "reminder_meeting_video_call_agent":{
        "action":"reminder_meeting_video_call_agent",
        "template_config":{
            "template_id": "01hs0z1xadbac2k1dqk7sc5mq4",
            "template_name": "reminder_meeting_video_call_agent",
            "type": "default"
        },
        "template_body": "Reminder!\n\n        Gentle Reminder for your upcoming meeting\n        with {{1}} at {{2}}.\n        \n        please join via {{3}}\n        \n        Thanks\n        {{4}}",
        "notification_user":"agent",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "update_meeting_video_call_agent":{
        "action":"update_meeting_video_call_agent",
        "template_config":{
            "template_id": "01hs0zc1217bmz5c606zmd2pvh",
            "template_name": "update_meeting_video_call_agent",
            "type": "default"
        },
        "template_body": "Hello {{1}},\n\n        Thanks for scheduling a call with {{2}}.\n        \n        Looking forward to connecting with you.\n        \n        Your meeting is scheduled at: {{3}}.\n        \n        Click on the below button to join the call.\n        {{4}}.\n        \n        Regards,\n        {{5}}.\n        Thanks!",
        "notification_user":"agent",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "meeting_reminder_customer":{
        "action": "meeting_reminder_customer",
        "template_config": {
            "template_id": "01hsayjnskbsxyf82nf74x9b2j",
            "template_name": "meeting_reminder_customer",
            "type": "default" 
        },
        "template_body": "Reminder!\n​\n        Hi There,\n        \n        This is a friendly reminder that your appointment is just around the corner, scheduled to begin in 15 minutes.\n        \n        Please join the call via below URL\n        {{1}}\n        \n        We're committed to making your experience exceptional!\n        \n        Thanks,\n        \n        {{2}}",
        "notification_user":"customer",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "reminder_meeting_schedule_call_customer":{
        "action":"reminder_meeting_schedule_call_customer",
        "template_config":{
            "template_id": "01hsaz4sg4zxf7m8p499qky263",
            "template_name": "reminder_meeting_schedule_call_customer",
            "type": "default"
        },
        "template_body": "Reminder!\n\n        Hi There,\n        \n        This is a friendly reminder that your appointment is just around the corner, scheduled to begin in 15 minutes.\n        \n        Please join the call via below URL\n        {{1}}\n        \n        We're committed to making your experience exceptional!\n        \n        Thanks,\n        \n        {{2}}",
        "notification_user":"customer",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "reminder_meeting_video_call_customer":{
        "action":"reminder_meeting_video_call_customer",
        "template_config":{
            "template_id": "01hsayzwbe3yjkfk9kz318czgk",
            "template_name": "reminder_meeting_video_call_customer",
            "type": "default"
        },
        "template_body": "Reminder!\n\n        Hi There,\n        \n        This is a friendly reminder that your appointment is just around the corner, scheduled to begin in 15 minutes.\n        \n        Please join the call via below URL\n        {{1}}\n        \n        We're committed to making your experience exceptional!\n        \n        Thanks,\n        \n        {{2}}",
        "notification_user":"customer",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "create_meeting_schedule_call_agent":{
        "action":"create_meeting_schedule_call_agent",
        "template_config":{
            "template_id": "01hs13jpq9tpn1dt33jddvd8mm",
            "template_name": "create_meeting_schedule_call_agent",
            "type": "default"
        },
        "template_body": "Alert !\n\n        Hello,\n        \n        {{1}} just scheduled a new video meeting with {{2}}.\n        \n        Details :\n        \n        Name : {{3}}.\n        Mobile : {{4}}.\n        Email : {{5}}.\n        \n        Scheduled At: {{6}}.\n        \n        Click on the below link to view all details.\n        {{7}}.\n        \n        Regards,\n        {{8}}.\n        Thanks!",
        "notification_user":"agent",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "create_meeting_schedule_call_customer":{
        "action":"create_meeting_schedule_call_customer",
        "template_config":{
            "template_id": "01hs10f5cxepnetb66ah7sw0wj",
            "template_name": "create_meeting_schedule_call_customer",
            "type": "default"
        },
        "template_body": "Hello {{1}},\n\n        Thanks for scheduling a call with {{2}}.\n        \n        Looking forward to connecting with you.\n        \n        Your meeting is scheduled at: {{3}}.\n        \n        Click on the below button to join the call.\n        {{4}}.\n        \n        Regards,\n        {{5}}.\n        Thanks!",
        "notification_user":"customer",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "invite_family_and_friends_customer":    {
        "action": "invite_family_and_friends_customer",
        "template_config": {
            "template_id": "01hk5347htx6kaf787hrhn6kfz",
            "template_name": "invite_family_and_friends_customer",
            "type": "default" 
        },
        "template_body": "Your friend {{1}} has invited you for group online shopping at {{2}} on {{3}}.\nJust click on below link and It will be fun to shop along with you.\nShopping Link : {{4}}\nThank you. Have a wonderful day ahead !",
        "notification_user":"customer",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "create_schedule_meet_video_call_agent":{
        "action":"create_schedule_meet_video_call_agent",
        "template_config":{
            "template_id": "01hs0yx944cttmbwmnxvan33g5",
            "template_name": "create_schedule_meet_video_call_agent",
            "type": "default"
        },
        "template_body": "Alert !\n\n        Hello,\n        \n        {{1}} just scheduled a new video meeting with {{2}}.\n        \n        Details :\n        \n        Name : {{3}}.\n        Mobile : {{4}}.\n        Email : {{5}}.\n        \n        Scheduled At: {{6}}.\n        \n        Click on the below link to view all details.\n        {{7}}.\n        \n        Regards,\n        {{8}}.\n        Thanks!",
        "notification_user":"agent",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "thanks_video_call_meeting_customer":{
        "action":"thanks_video_call_meeting_customer",
        "template_config":{
            "template_id": "01j7jysg2dk35tx3hej8pk9ge2",
            "template_name": "thanks_video_call_meeting_customer",
            "type": "default"
        },
        "template_body": "Dear {{1}},\nI hope this message finds you well.\nThank you for taking the time to connect with us.\nIt was a pleasure assisting you, and we truly appreciate the opportunity to help you from the convenience of your home.\nIf you have any further questions or need additional assistance, please don't hesitate to reach out.\nWe're always here to support you and look forward to serving you again soon.\nThank you once again for choosing {{2}}.\nBest regards,\n{{3}}.",
        "notification_user":"customer",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "reminder_schedule_call_meeting_customer":{
        "action":"reminder_schedule_call_meeting_customer",
        "template_config":{
            "template_id": "01j6vry9m3jwj7kzh5agg6ysah",
            "template_name": "reminder_schedule_call_meeting_customer",
            "type": "default"
        },
        "template_body": "Reminder! Hello {{1}} Greetings from {{2}} You have an upcoming meeting in the next {{3}}. Simply join the video meeting by clicking the below link. {{4}} Click the below link to confirm your availability {{5}} Thanks {{6}}",
        "notification_user":"customer",
        "notification_type":"outbound",
        "notification_channel": "whatsapp"
    },
    "cancelled_schedule_meeting_agent":{
        "action": "cancelled_schedule_meeting_agent",
        "template_config": {
            "template_id": "01j0qka4gn98sgnkg3jvvq75pa",
            "template_name": "cancelled_schedule_meeting_agent",
            "type": "default"
        },
        "template_body": "Greetings from  {{1}}!\nYour meeting with us has been cancelled now.\nIf this time did not work for you, please select your preferred time slot by  {{2}}.\nWe would love to connect with you.\nBest regards,\nTeam  {{3}}",
        "notification_channel": "whatsapp",
        "notification_user":"agent",
        "notification_type":"inbound",
    },
    "cancelled_schedule_meeting_customer":{
        "action": "cancelled_schedule_meeting_customer",
        "template_config": {
            "template_id": "01j0qkcfeefpfhxdka854t3p4v",
            "template_name": "cancelled_schedule_meeting_customer",
            "type": "default"
        },
        "template_body": "Greetings from  {{1}}!\nYour meeting with us has been cancelled now.\nIf this time did not work for you, please select your preferred time slot by  {{2}}.\nWe would love to connect with you.\nBest regards,\nTeam  {{3}}",
        "notification_channel": "whatsapp",
        "notification_user":"customer",
        "notification_type":"inbound",

    },

}