import React, { Component, Fragment } from "react";
import { renderToString } from 'react-dom/server';

import { func } from "prop-types";
import HelpDescription from "../Components/TooltipsPopovers/HelpDescription";

export var capitalize = function (text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export var UpperCase = function (text) {
  return text.toUpperCase();
}

export function isEmail(text) {
  if(!text) {return text;}
  return RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i).test(text);
}

export function isPhoneNumber(text) {
  if(!text) {return text;}
  text = text.replace(/[.\s-]/g,'');
  return RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,16}$/i).test(text);
}

export function sanitizeText(text) {
  if(!text) {return text;}
  const allowed_chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-".split('');
  var sanitized = "";
  text.split('').forEach(char => {
    if (allowed_chars.includes(char)) {
      sanitized += char;
    } else {
      sanitized += "_";
    }
  });
  return sanitized;
}

export function getMatches(string, regex, index) {
  index || (index = 1); // default to the first capturing group
  var matches = [];
  var match;
  while (match = regex.exec(string)) {
    matches.push(match[index]);
  }
  return matches;
}

export function removeBrackets(text){
  if(!text) {return text;}
  return (text.replace(/[\])}[{(]/g, ''));
}
export function underscoreToSpacedCamel(text){
  if(!text) {return text;}
  return capitalize(text.replace(/_/g, " "));
}

export function underscoreToCamel(text){
  if(!text) {return text;}
  return capitalize(text.replace(/_/g, ""));
}

export function allowPositiveIntegers (e) {
  const characterCode = e.key
  if (characterCode === 'Backspace') return

  const characterNumber = Number(characterCode)
  if (characterNumber >= 0 && characterNumber <= 9) {
    if (e.currentTarget.value && e.currentTarget.value.length) {
      return
    } else if (characterNumber === 0) {
      e.preventDefault()
    }
  } else {
    e.preventDefault()
  }
}

export function analyticsHelp(key) {
  if(key === "widget open percentage") {
    return "Percent times widget was opened per widget load."
  } else if (key === "CTA click percentage") {
    return "Percent times visitor clicked on a CTA button per widget open action."
  } else if(key === "form fill percentage") {
    return "Percent times forms filled per CTA click.";
  } else if(key === "unique sessions") {
    return "Same visitor session does not required a form re-fill. Lasts 1 hour.";
  } else if(key === "engagement percentage") {
    return "Percent of unique engagements per session.";
  } else if(key === "unique engagements") {
    return "Session count that has any engagement.";
  } else {
    return null;
  }
  return "HOLLA";
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}