import { makeObservable } from "./Observable";

export const widgetContext = makeObservable({
  value: {
    non_working_dates: [],
    theme: "saleassist",
  },
});

export const guideTours = makeObservable({
  name: "guide_tours",
  ttlHours: 4380,
  useCookie: true,
  value: {}
});
