import React, {Component} from 'react';
import CenterMessage from '../Components/Loaders/CenterMessage';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { logout } from './User';


class PopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open:true,
    };
  }
  render() {
    return (
      <Modal isOpen={this.state.open}>
        <ModalBody>
        <ModalHeader>{this.props.error}</ModalHeader>
          {this.props.msg}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={()=>{if(this.props.onClick){this.props.onClick()}; this.props.error==="Session Expired"?logout():window.location.reload()}}>Refresh</Button>{' '}
          <Button color="secondary" onClick={()=>{if(this.props.onClick){this.props.onClick()}; this.setState({open:false})}}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default PopUp;