import React, { Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { Link, withRouter } from "react-router-dom";

import ResizeDetector from "react-resize-detector";

import AppMain, { AppPublic } from "../../Layout/AppMain";
import {
  needLogin,
  getToken,
  resetToken,
  getAuthUser,
} from "../../States/Token";
import { setUser } from "../../States/User";
import { setClient } from "../../States/Client";
import { getCall, postCall, putCall } from "../../Helpers/Api";
import { config } from "../../config";
import { bottomAlert } from "../../Components/Notifications/ToastAlerts";
import { getUser, logout } from "../../Helpers/User";
import { currentTimeInMilliSecs } from "../../Helpers/Time";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { setPermissions } from "../../States/Permissions";
import { hasCookie } from "../../Helpers/Cookie";
import { couponCodeContext } from "../../States/Coupons";
import { getClient } from "../../Helpers/Client";
import { setExperiences } from "../../States/MeetingExperiences";
// import { io } from "socket.io-client";
// import { receiveMessage } from "../../SA-VIdeo-Call/js/helper/chatBox";
// import { socketContext } from "../../States/Socket";
import { io } from "socket.io-client";
import { socketContext } from "../../States/Socket";
import WebSocketManager from "../../States/WebSocketManager";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
      user_is_valid: false,
    };
  }

  componentDidMount() {
    this.setState({ user_is_valid: false });
    couponCodeContext.set(
      new URLSearchParams(this.props.location.search).get("coupon")
    );
    needLogin({
      onSuccess: (is_authenticated) => {
        if (is_authenticated) {
          getCall({
            url: config.url.USERS,
            path: "fetch_or_create",
            onSuccess: (response) => {
              getCall({
                url: config.url.MEETINGS,
                path: "/meeting_experiences/all",
                onSuccess: (response) => {
                  setExperiences(response.data);
                  this.setState({ changed: !this.state.changed });
                },
                onFailure: (error) => {
                  bottomAlert(
                    "Unable to fetch meeting experiences. " +
                      ((error.response && error.response.data.detail) ||
                        error.toString()),
                    "warning"
                  );
                },
              });
              if (couponCodeContext.get() !== null) {
                postCall({
                  url: config.url.CLIENTS,
                  path: "/use_coupon/" + couponCodeContext.get(),
                  onSuccess: (response) => {
                    this.setState({ client: response.data });
                    bottomAlert("Coupon successfully activated", "success");
                    couponCodeContext.reset();
                  },
                  onFailure: (error) => {
                    bottomAlert(
                      "Cannot use coupon, " +
                        ((error.response && error.response.data.detail) ||
                          error.toString()),
                      "warning"
                    );
                  },
                });
              }
              if (
                !hasCookie("saleassist_auth_token") &&
                getAuthUser().email &&
                !getAuthUser().emailVerified
              ) {
                resetToken();
                this.setState({ user_is_valid: false });
                this.props.history.push("/auth/verify_email");
                return;
              }

              setUser(response.data.user);
              setPermissions(response.data.permissions);
              setClient(response.data.client);

              if (!getClient().is_enabled) {
                this.setState({ user_is_valid: false });
                bottomAlert("Client account is disabled.", "warning");
                logout();
                return;
              } else if (getClient().plan_status !== "active") {
                this.setState({ user_is_valid: false });
                bottomAlert("Client plan is not active.", "warning");
                logout();
                return;
              } else if (!getUser().is_enabled) {
                this.setState({ user_is_valid: false });
                bottomAlert("User account is disabled.", "warning");
                logout();
                return;
              } else {
                this.setState({ user_is_valid: true });

                const socket = io(config.url.SOCKET_URL, {
                  query: `id=${getUser().id}&type=agent&uid=${
                    getUser().email
                  }&client_id=${getClient().id}`,
                });

                socket.on("connect", (data) => {
                  console.log(data, "socket connected successfully");

                  const instance = new WebSocketManager(socket);
                  Object.freeze(instance);

                  socketContext.set({ socket: socket, wsmSocket: instance });

                  instance.on("people_dropped", (data) => {
                    // console.log("User dropped the call from widget", data);
  
                    if(data.message === "people_dropped"){
                      bottomAlert("User dropped from the call", "error");
                    }
                  }, "event_from_main");
                });

                getCall({
                  url: "https://api.ipify.org?format=json",
                  onSuccess: (response) => {
                    const ip = response.data;

                    getCall({
                      url: `https://ipapi.co/${ip}/json/`,
                      onSuccess: (response) => {
                        putCall({
                          url: config.url.UPDATE_LOCAITON,
                          path:`/${getUser().email}`,
                          data: {
                            location: {
                              city: response.data.city,
                              state: response.data.region
                            }
                          },
                          onSuccess: (response) => {},
                          onFailure: (error) => {
                            bottomAlert(
                              "Cannot update location for the agent, " +
                                ((error.response && error.response.data.detail) ||
                                  error.toString()),
                              "error"
                            );
                          }
                        })
                      },
                      onFailure: (error) => {
                        bottomAlert(
                          "Cannot fetch IP location info, " +
                            ((error.response && error.response.data.detail) ||
                              error.toString()),
                          "error"
                        );
                      },
                      auth: false
                    })
                  },
                  onFailure: (error) => {
                    bottomAlert(
                      "Cannot fetch IP, " +
                        ((error.response && error.response.data.detail) ||
                          error.toString()),
                      "error"
                    );
                  },
                  auth: false
                })

                // var socket = io(config.url.SOCKET_URL , {
                //   query: `id=${response.data.user.id}&client_id=${response.data.client.id}&type=agent`,
                // });

                // // window.socket = socket;

                // socket.on("connect", (data) => {
                //   console.log(data, "socket connected successfully");
                //   this.socket = socket;
                //   socketContext.set({socket: socket});
                //   // socket.emit('chat_message', {"text":"Test message from dashboard!!", "meeting_id": "51f3cf67-2a13-4f23-994d-abcfec416892"});
                // });

                // socket.on("message", async (data) => {
                // console.log("Message from socket", data);

                // if(data.message === "people_connected") {
                //   bottomAlert("User joined the call", "success");
                // }

                // if(data.message === "people_disconnected") {
                //   bottomAlert("User left the call", "error");
                // }
                // });

                // socket.on("chat_message", async (data) => {
                //   // console.log("Chat message from socket", data);
                //   for (const key in data) {
                //     if (key === "text") {
                //       receiveMessage(`user`, data.text);
                //     }
                //   }
                // });
              }

              if (response.status == 201) {
                bottomAlert(
                  "Hello " +
                    response.data.user.display_name +
                    ", Welcome to SaleAssist. Your account has been created.",
                  "success",
                  true
                );
              }
              // this.props.history.push(get_landing_page());
            },
            onFailure: (error) => {
              // Need to create an endpoint to register failures
              bottomAlert(
                "Unable to authorize your identity. " +
                  ((error.response && error.response.data.detail) ||
                    error.toString()),
                "warning"
              );
              logout();
              return;
            },
          });
        } else {
          if (window.location.href.split("/")[4] === "phone_login") {
            this.props.history.push("/auth/phone_login");
          } else if (
            window.location.href
              .split("/")[4]
              .startsWith("login-with-email-otp")
          ) {
            this.props.history.push(window.location.href.split("/")[4]);
          } else {
            this.props.history.push("/auth/login");
          }
          return;
        }
      },
      onFailure: (error) => {
        if (window.location.href.split("/")[4] === "phone_login") {
          this.props.history.push("/auth/phone_login");
        } else {
          this.props.history.push("/auth/login");
        }
        return;
      },
    });
  }

  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;
    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                { "fixed-footer": enableFixedFooter },
                { "closed-sidebar": enableClosedSidebar || width < 1250 },
                {
                  "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                },
                { "sidebar-mobile-open": !enableMobileMenu },
                { "body-tabs-shadow-btn": enablePageTabsAlt }
              )}
            >
              {this.state.user_is_valid && getToken().token ? (
                <>
                  <AppMain />
                </>
              ) : (
                <AppPublic />
              )}
            </div>
            {getToken()["token"] &&
              getToken()["expire_at"] < currentTimeInMilliSecs() && (
                <Modal isOpen={true} fade={false}>
                  <ModalHeader>Session expired!</ModalHeader>
                  <ModalBody>
                    Your session has expired. Please reload the page.
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={() => {
                        logout();
                      }}
                    >
                      Reload now
                    </Button>{" "}
                  </ModalFooter>
                </Modal>
              )}
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));
