import { createReduxModule } from "hooks-for-redux";
import { makeObservable } from "./Observable";


export const userIdentity = makeObservable({name: "user_identity", ttlHours: 24, useLocalStorage: true, value: null});

export const [user, {setUser, resetUser}, userStore] = createReduxModule(
  "user",
  {},
  {
    setUser:(user, userObject) => {user = userObject; userIdentity.set(user.user_name); return user},
    resetUser:(user) => {user = {}; return user}
  }
);
