import { config } from "../config";
import { clientStore, setClient } from "../States/Client";
import { getCall } from "./Api";

export function getClient(){
  return clientStore.getState();
}

export function fetchClient(onSuccess, onFailure) {
  getCall({
    url: config.url.CLIENTS,
    path: getClient().id,
    onSuccess: (response) => {
      onSuccess(response);
    },
    onFailure: (error) => {
      onFailure(error);
    }
  });
}