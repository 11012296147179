import React from 'react';
import {Form, FormGroup, Label, Input, Button} from "reactstrap";
import {getActiveClient} from "../../../Helpers/Plan";
import {config} from "../../../config";
import {setClient} from "../../../States/Client";
import { putCall } from "../../../Helpers/Api";
import {bottomAlert} from "../../Notifications/ToastAlerts";
import OrganizationPhoto from "../../../assets/images/Organization.png";
import { getUser } from '../../../Helpers/User';

class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = getActiveClient();

    if(
      this.state.name.includes(`${getUser().user_name.split('@')[0]}`) &&
      `${getUser().user_name.split('@')[0]}`.length + 6 == this.state.name.length
    ){
      this.state.name = "";
    }

    this.handleSubmitOrganization = this
      .handleSubmitOrganization
      .bind(this);
    this.handleChange = this
      .handleChange
      .bind(this);
  }

  handleSubmitOrganization = (e) => {
    e.preventDefault();
    if(!this.state.name.trim().length) {
      this.setState({name: ""});
      bottomAlert("Please fill in the organization name.", "warning");
      return;
    }

    putCall({
      url: config.url.CLIENTS,
      path: this.state.id,
      data: this.state,
      onSuccess: (response) => {
        bottomAlert("Organization name changed", "success");
        setClient(this.state);
        if(this.props.onSuccess){this.props.onSuccess();}
      },
      onFailure: (error) => {
        bottomAlert("Unable to complete your request. " + ((error.response && error.response.data.detail) || error.toString()), "warning");
      }
    })
  }

  handleChange = (e) => {
    this.setState({name: e.target.value})
  }
  render() {
    return (
    <div style={{
      minHeight: '300px'
    }}>
      <div className="organization-image"><img src={OrganizationPhoto} alt="profile"/></div>
      <h2>Your organization</h2>
      <Form onSubmit={this.handleSubmitOrganization}>
        <FormGroup>
          <Label for="oragnization_name">Oragnization Name</Label>
          <Input
            type="text"
            name="oragnization_name"
            id="oragnization_name"
            placeholder="A name to identify your organization"
            value={this.state.name}
            onChange={this.handleChange}/>
          <Button color="success" className="mt-2">Save</Button>
        </FormGroup>
      </Form>
    </div>
  )
}
}

export default Organization