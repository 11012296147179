import { makeObservable } from "./Observable";

export const meetingContext = makeObservable();
export const callRequestContext = makeObservable();
export const activeRequestContext = makeObservable();
export const agentStatus = makeObservable();
export const agentHasIncomingRequests = makeObservable();
export const incomingRequests = makeObservable();
export const meetingAction = makeObservable();
export const snoozeRing = makeObservable({value: false});
export const streamingStatus = makeObservable({value: {}});
export const chatItemContext = makeObservable();
export const userSession = makeObservable({value: {}});
export const crumbsTrail = makeObservable({value: []});