import React from "react";
import CenterMessage from "../Loaders/CenterMessage";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

export default class ProcessingOverlay extends React.Component {
  render() {
    return (
      <Modal isOpen={this.props.show}>
        <ModalBody>
          <CenterMessage
            message={this.props.message || "Processing your request"}
            description=" "
            backdrop="static"
            loader_type={this.props.loader_type}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}
