import React, { useState } from "react";
import { Popover } from "@mui/material";
import { FaWhatsapp } from "react-icons/fa";
import Draggable from "react-draggable";
import { incomingRequests } from "../../../States/Meetings";

function WhatsappNotification({ children, incomingCallsLength }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dragCompleted, setDragCompleted] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(dragCompleted ? event.currentTarget : null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const captureClick = (e) => {
    e.stopPropagation();
    setDragCompleted(true);
    window.removeEventListener("click", captureClick, true);
  };

  const handleDrag = (e) => {
    setDragCompleted(false);
    window.addEventListener("click", captureClick, true);
  };

  const open = Boolean(anchorEl);

  return (
    incomingCallsLength > 0 && (
      <Draggable bounds="body" onDrag={handleDrag}>
        <div className="whatsapp-notification-floating-action-button">
          <div className="wa-logo-button-wrapper" onClick={handleClick}>
            <div className="wa-logo-button">
              <span className="wa-badge">
                {incomingCallsLength > 10 ? "10+" : incomingCallsLength}
              </span>
              <FaWhatsapp />
            </div>
          </div>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div style={{ maxWidth: 340 }}>{children}</div>
          </Popover>
        </div>
      </Draggable>
    )
  );
}

export default WhatsappNotification;
