import React, { Fragment } from 'react';
import { Tooltip } from "reactstrap";

export default class HelpDescription extends React.Component {
  constructor(props) {
    super(props);
    this.id = "id_" + Math.random().toString(36).substring(7);

    this.state = {
      tooltipOpen: false
    }
  }

  render() {
    if(this.props.content && this.props.content.length > 0) {
      return (
        <Fragment>
          <span className="tooltip-element" id={this.id}>?</span>
          <Tooltip placement="top-start" isOpen={this.state.tooltipOpen}
            target={this.id} toggle={() => this.setState({tooltipOpen: !this.state.tooltipOpen})}>
            {this.props.content}
          </Tooltip>
        </Fragment>
      );
    } else {
      return "";
    }
  }
}
