export function setLocalStorage(key, value, ttlHours=1) {
	try {
		const now = new Date()

		// `item` is an object which contains the original value
		// as well as the time when it's supposed to expire
		const item = {
			value: value,
			expiry: now.getTime() + (ttlHours * 1000 * 60 * 60),
		}
		window.localStorage.setItem(key, JSON.stringify(item));
		return value;
	}
	catch(err) {
		return null;
	}
}

export function getLocalStorage(key) {
	try {
		const itemStr = window.localStorage.getItem(key)
		// if the item doesn't exist, return null
		if (!itemStr) {
			return null
		}
		const item = JSON.parse(itemStr);
		const now = new Date();
		// compare the expiry time of the item with the current time
		if (now.getTime() > item.expiry) {
			// If the item is expired, delete the item from storage
			// and return null
			window.localStorage.removeItem(key);
			return null;
		}
		return item.value;
	}
	catch(err) {
		return null;
	}
}