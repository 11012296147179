import React, { Component, Fragment } from "react";
import {getCall, postCall} from "../../../../Helpers/Api"
import {msToShortDateString} from "../../../../Helpers/Time"
import { config } from "../../../../config";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import PerfectScrollbar from "react-perfect-scrollbar";
import NotificationElement from "./NotificationElement";


class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state={
      notifications: this.props.notifications
    }

  }

  render() {
    // alert("j");
    var notifications=[];
    if(this.props.notifications.length) {
      this.props.notifications.forEach(notification => {
        notifications.push(
          <NotificationElement key={notification.id} notification={notification}/>
        );
      });
    }

    return (
      <Fragment>
        <div className="scroll-area-sm" style={{maxHeight:"200px",height:"auto"}}>
          <PerfectScrollbar>
            <div className="p-3">
              <VerticalTimeline className="vertical-time-simple vertical-without-time" layout="1-column">
                {notifications}
              </VerticalTimeline>
            </div>
          </PerfectScrollbar>
        </div>
      </Fragment>
    );
  }
}

export default Notifications;
