import {EventSourcePolyfill} from 'ng-event-source';
import { postCall } from './Api';
import { currentTimeInMilliSecs, noDelaySetInterval } from './Time';


export function sseObservable({url=null, headers={}}={}) {
  let listeners = [];
  let errorListeners = [];
  let connection = null;
  let last_message_time = null;
  let timer = null;

  function ping(sse_endpoint) {
    if(currentTimeInMilliSecs() - last_message_time > 10000) {
      postCall({
        url: sse_endpoint
      });
    }
  }

  function connect(connect_url) {
    if(!connection || !connection.OPEN) {
      connection = new EventSourcePolyfill(connect_url || url, {headers: headers});
      connection.onmessage = (response) => {
        last_message_time = currentTimeInMilliSecs();
        listeners.forEach((l) => l(JSON.parse(response.data)));
      }
      connection.onerror = (error) => {
        errorListeners.forEach((l) => l(error));
      }
      timer && clearInterval(timer);
      timer = noDelaySetInterval(() => ping(connect_url || url), 1000);
    }
  }

  function close() {
    connection && connection.OPEN && connection.close();
    connection = null;
    timer && clearInterval(timer);
    timer = null;
  }

  function subscribe(listenerFunc) {
    var has_element = false;
    listeners.map(listener => {
      if(listener === listenerFunc) {
        has_element = true;
      }
    });
    if(!has_element) {
      listeners.push(listenerFunc);
    }
    return () => unsubscribe(listenerFunc); // will be used inside React.useEffect
  }

  function unsubscribe(listenerFunc) {
    listeners = listeners.filter((l) => l !== listenerFunc);
  }

  function subscribeError(listenerFunc) {
    var has_element = false;
    errorListeners.map(listener => {
      if(listener === listenerFunc) {
        has_element = true;
      }
    });
    if(!has_element) {
      errorListeners.push(listenerFunc);
    }
    return () => unsubscribe(listenerFunc); // will be used inside React.useEffect
  }

  function unsubscribeError(listenerFunc) {
    errorListeners = errorListeners.filter((l) => l !== listenerFunc);
  }

  function clearListeners() {
    listeners = [];
  }

  function clearErrorListeners() {
    errorListeners = [];
  }

  return {
    connect,
    close,
    subscribe,
    unsubscribe,
    clearListeners,
    subscribeError,
    unsubscribeError,
    clearErrorListeners
  };
}