import { createReduxModule } from "hooks-for-redux";

export const [group_id_member_list_context, {setgroup_id_member_list_Context, resetgroup_id_member_list_Context}, group_id_member_list_ContextStore] = createReduxModule(
  "group_id_member_list_context",
  {},
  {
    setgroup_id_member_list_Context:(group_id_member_list_context, group_id_member_list_ContextObject) => {group_id_member_list_context = group_id_member_list_ContextObject; return group_id_member_list_context},
    resetgroup_id_member_list_Context:(group_id_member_list_context) => {group_id_member_list_context = {}; return group_id_member_list_context}
  }
);

export function getgroup_id_member_list_Context() {
  return group_id_member_list_ContextStore.getState();
}