import React from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col
} from "reactstrap";
import {widgetContext } from "../../../States/Source";
import { postCall } from '../../../Helpers/Api';
import { config } from '../../../config';
import { getUser } from "../../../Helpers/User";
import { bottomAlert } from "../../../Components/Notifications/ToastAlerts";
import { Divider } from '@material-ui/core';


class SourceIntend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {...widgetContext.get(),
      isOpen: false,
      theme:"saleassist",
      name:"",
      source_intent:'general'
    }
    this.handleSubmitSourceIntend = this
      .handleSubmitSourceIntend
      .bind(this);
  }

  handleSubmitSourceIntend = (e) => {
    e.preventDefault();
    if(!this.state.name.trim().length) {
      this.setState({name: ""});
      bottomAlert("Please fill in the widget name.", "warning");
      return;
    }
    postCall({
      url: config.url.SOURCES,
      data: {agents: {'default': getUser().user_name}, scheduling_agents: [getUser().user_name], theme: this.state.theme, name: this.state.name, source_intent: this.state.source_intent},
      onSuccess: (response) => {
        bottomAlert("Widget has been created.", "success");
        this.setState(response.data);
        this.setState({show_overlay: false});
        if(this.props.onSuccess){this.props.onSuccess();}
      },
      onFailure: (error) => {
        bottomAlert("Unable to complete your request. " + ((error.response && error.response.data.detail) || error.toString()), "warning");
        this.setState({show_overlay: false});
      }
    })
  }

  toggleSourceIntentDropdown = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  render() {
    return (
      <React.Fragment>
        <div style={{
          minHeight: '300px'
        }}>
          <Label>Widget appears on your website or is integrated as a button to drive enriching customer experience.</Label>
          <h2>Create Widget</h2>
          <Divider />
          <Form onSubmit={this.handleSubmitSourceIntend}>
            <FormGroup>
              <Label style={{marginTop: "15px"}} for="display_name">Widget Name</Label>
              <Input
                type="text"
                name="source_name"
                id="source_name"
                placeholder="Widget Name"
                value={this.state.name}
                onChange={(e)=>this.setState({name:e.target.value})}/>
              {/* <Label style={{marginTop: "15px"}} for="display_name">Intent</Label>
              <InputGroupButtonDropdown
                style={{display: "block"}}
                className="btn-outline-clear"
                addonType="prepend"
                isOpen={this.state.isOpen}
                toggle={this.toggleSourceIntentDropdown}>
                <DropdownToggle caret>{underscoreToSpacedCamel(this.state.source_intent)}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={()=>{this.setState({source_intent:'general'})}} selected>General</DropdownItem>
                  <DropdownItem onClick={()=>{this.setState({source_intent:'home_page'})}} >Home Page</DropdownItem>
                  <DropdownItem onClick={()=>{this.setState({source_intent:'product_page'})}} >Product Page</DropdownItem>
                  <DropdownItem onClick={()=>{this.setState({source_intent:'cart_page'})}} >Cart Page</DropdownItem>
                  <DropdownItem onClick={()=>{this.setState({source_intent:'checkout_page'})}} >Checkout Page</DropdownItem>
                  <DropdownItem onClick={()=>{this.setState({source_intent:'contact_page'})}} >Contact Page</DropdownItem>
                  <DropdownItem onClick={()=>{this.setState({source_intent:'blog'})}} >Blog</DropdownItem>
                </DropdownMenu>
              </InputGroupButtonDropdown> */}
              <Row form>
                <Col md={12}>
                  <Label style={{marginTop: "15px"}} for="thankyou_message">Theme color</Label>
                  <br/>
                  <Button
                    outline={this.state.theme != "saleassist"}
                    className="mb-2 mr-2 btn-transition"
                    color="saleassist"
                    style={{
                    padding: '15px'
                  }}
                    onClick={() => this.setState({theme: "saleassist"})}/>
                  <Button
                    outline={this.state.theme != "primary"}
                    className="mb-2 mr-2 btn-transition"
                    color="primary"
                    style={{
                    padding: '15px'
                  }}
                    onClick={() => this.setState({theme: "primary"})}/>
                  <Button
                    outline={this.state.theme != "secondary"}
                    className="mb-2 mr-2 btn-transition"
                    color="secondary"
                    style={{
                    padding: '15px'
                  }}
                    onClick={() => this.setState({theme: "secondary"})}/>
                  <Button
                    outline={this.state.theme != "success"}
                    className="mb-2 mr-2 btn-transition"
                    color="success"
                    style={{
                    padding: '15px'
                  }}
                    onClick={() => this.setState({theme: "success"})}/>
                  <Button
                    outline={this.state.theme != "info"}
                    className="mb-2 mr-2 btn-transition"
                    color="info"
                    style={{
                    padding: '15px'
                  }}
                    onClick={() => this.setState({theme: "info"})}/>
                  <Button
                    outline={this.state.theme != "warning"}
                    className="mb-2 mr-2 btn-transition"
                    color="warning"
                    style={{
                    padding: '15px'
                  }}
                    onClick={() => this.setState({theme: "warning"})}/>
                  <Button
                    outline={this.state.theme != "danger"}
                    className="mb-2 mr-2 btn-transition"
                    color="danger"
                    style={{
                    padding: '15px'
                  }}
                    onClick={() => this.setState({theme: "danger"})}/>
                  <Button
                    outline={this.state.theme != "focus"}
                    className="mb-2 mr-2 btn-transition"
                    color="focus"
                    style={{
                    padding: '15px'
                  }}
                    onClick={() => this.setState({theme: "focus"})}/>
                  <Button
                    outline={this.state.theme != "alternate"}
                    className="mb-2 mr-2 btn-transition"
                    color="alternate"
                    style={{
                    padding: '15px'
                  }}
                    onClick={() => this.setState({theme: "alternate"})}/>
                  <Button
                    outline={this.state.theme != "dark"}
                    className="mb-2 mr-2 btn-transition"
                    color="dark"
                    style={{
                    padding: '15px'
                  }}
                    onClick={() => this.setState({theme: "dark"})}/>
                  <Button
                    outline={this.state.theme != "light"}
                    className="mb-2 mr-2 btn-transition"
                    color="light"
                    style={{
                    padding: '15px'
                  }}
                    onClick={() => this.setState({theme: "light"})}/>
                </Col>
              </Row>
              <Button color="success" className="mt-2">Save</Button>
            </FormGroup>
          </Form>
        </div>
      </React.Fragment>
    )
  }
}
export default SourceIntend