import { auth, messaging } from "../Auth/Firebase";
import { resetClient } from "../States/Client";
import { resetToken } from "../States/Token";
import { userStore } from "../States/User";
import { widgetContext } from "../States/Source";
import { callRequestContext, meetingContext } from "../States/Meetings";
import { resetPermissions } from "../States/Permissions";
import { resetUser } from "../States/User";
import { resetrole_id_permi_id_Context } from "../States/Role_id_Permi_id";
import { resetgroup_id_member_list_Context } from "../States/Group_id_Member_list";
import { hasCookie, setCookie } from "./Cookie";
import { deleteCall } from "./Api";
import { config } from "../config";
import { bottomAlert } from "../Components/Notifications/ToastAlerts";

export function getUser(){
  return userStore.getState();
}

export function app_cleanup() {
  resetClient();
  resetToken();
  widgetContext.reset()
  meetingContext.reset();
  callRequestContext.reset();
  resetPermissions();
  resetrole_id_permi_id_Context();
  resetUser();
  resetgroup_id_member_list_Context();
  hasCookie("saleassist_auth_token") && setCookie("saleassist_auth_token", '');
}

export function logout() {
  if (messaging && messaging.requestPermission === "granted") {
    deleteCall({
      url: config.url.USERS,
      path: "/identity/user_device_token",
      data: {
        device_token: messaging.getToken(),
        operating_system: navigator.appVersion,
        user_agent: navigator.userAgent
      }
    });
  }
  auth.signOut().then(response => {
    app_cleanup();
    window.location.href = "/auth/login";
  }).catch(error => {
    bottomAlert("Unable to logout. " + ((error.response && error.response.data.detail) || error.toString()), "warning");
  });
}
