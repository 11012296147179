import { createReduxModule } from "hooks-for-redux";

export const [permissions, {setPermissions, resetPermissions}, permissionsStore] = createReduxModule(
  "permissions",
  [],
  {
    setPermissions:(permissions, permissionsObject) => {permissions = permissionsObject; return permissions},
    resetPermissions:(permissions) => {permissions = {}; return permissions}
  }
);

export function getPermissions() {
  return permissionsStore.getState();
}

export function isAllowed(permission_name) {
  return getPermissions().includes(permission_name) || getPermissions().includes("admin");
}