import React, { Fragment } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import HeaderLogo from "../AppLogo";
import UserBox from "./Components/UserBox";
import SmHeaderDots from "./Components/SmHeaderDots";
import { getCall } from "../../Helpers/Api";
import { config } from "../../config/index";
import BoardingFormsLongLasting from "../../Components/BoardingFormLongLasting/index";
import HeaderDots from "./Components/HeaderDots";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BoardingForm: false,
      clientCreated: false,
    };
  }
  componentDidMount() {
    getCall({
      url: config.url.USERS,
      path: "fetch_or_create",
      onSuccess: (response) => {
        this.setState({ clientCreated: response.status === 201 });
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to fetch user. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
      },
    });
  }
  render() {
    let { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } =
      this.props;
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          className={cx("app-header", headerBackgroundColor, {
            "header-shadow": enableHeaderShadow,
          })}
          transitionName="HeaderAnimation"
          transitionAppear={false}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          <BoardingFormsLongLasting />
          <HeaderLogo />
          <div
            className={cx("app-header__content", {
              // "header-mobile-open": enableMobileMenuSmall,
              "header-mobile-open": true,
            })}
          >
            <div className="app-header-left">
              {/* <SearchBox /> */}
              {/* <MegaMenu /> */}
            </div>
            <div className="app-header-right">
              <HeaderDots />
              <UserBox />
              <SmHeaderDots mode="self" />
              {/* <HeaderRightDrawer /> */}
            </div>
          </div>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
