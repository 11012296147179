import firebase_app from "firebase/app";
// import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import { bottomAlert } from "../Components/Notifications/ToastAlerts";

var firebaseConfig = {
  apiKey: "AIzaSyAOiEsFvEaOwD3ZQVNozjpCBefw5Ev84qw",
  authDomain: "safemeet-auth.firebaseapp.com",
  databaseURL: "https://safemeet-auth.firebaseio.com",
  projectId: "safemeet-auth",
  storageBucket: "safemeet-auth.appspot.com",
  messagingSenderId: "714965350667",
  appId: "1:714965350667:web:0860884f18834c7c754a0e",
  measurementId: "G-2ZKPF3N4NN",
};
// Initialize Firebase
firebase_app.initializeApp(firebaseConfig);
// firebase.analytics();

export const auth = firebase_app.auth();
export const recaptchaVerifier = firebase_app.auth.RecaptchaVerifier;
export const firestore = firebase_app.firestore();
let messaging_var = null;
if (firebase_app.messaging.isSupported()) {
  messaging_var = firebase_app.messaging();
}
export const messaging = messaging_var;
export const provider = new firebase_app.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
  auth
    .signInWithPopup(provider)
    .then(
      (response) =>
        // window.location.replace(get_landing_page())
        null
    )
    .catch((error) => bottomAlert(error.message, "error"));
};
