import { getCookie, hasCookie, setCookie } from './../Helpers/Cookie';
import { getLocalStorage, setLocalStorage } from './../Helpers/Storage';

export function makeObservable({
  value = null,
  name = null,
  ttlHours = 1,
  useLocalStorage = false,
  useCookie = false,
} = {}) {
  let listeners = []; // initial listeners can be passed an an argument aswell
  let defaultStorageValue = value;
  let storageValue = value;
  let storageIdentifier = name;
  let storageTTL = ttlHours ? ttlHours : 1; // in hours

  function get(key) {
    if (useCookie && storageIdentifier && hasCookie(storageIdentifier)) {
      let cookie_value = getCookie(storageIdentifier);
      if(cookie_value !== storageValue) {
        storageValue = cookie_value;
      }
    }
    if (useLocalStorage && storageIdentifier && getLocalStorage(storageIdentifier)) {
      let storage_value = getLocalStorage(storageIdentifier);
      if(storage_value !== storageValue) {
        storageValue = storage_value;
      }
    }
    if (key) {
      if (storageValue instanceof Object && storageValue.constructor === Object) {
        return storageValue[key];
      } else {
        throw 'Value stored is not an object.';
      }
    } else {
      return storageValue;
    }
  }

  function set(newValue, keyValue) {
    if (defaultStorageValue instanceof Object && defaultStorageValue.constructor === Object) {
      // if default is dict and new is dict then update the dict
      if (newValue instanceof Object && newValue.constructor === Object) {
        if (keyValue) {
          throw 'Key cannot be an object.';
        }
        if (storageValue instanceof Object && storageValue.constructor === Object) {
          storageValue = { ...storageValue, ...newValue };
        } else {
          storageValue = newValue;
        }
      } else {
        if (storageValue instanceof Object && storageValue.constructor === Object) {
          storageValue[newValue] = keyValue;
        } else {
          storageValue = {};
          storageValue[newValue] = keyValue;
        }
      }
    } else {
      if (keyValue) {
        if (newValue instanceof Object && newValue.constructor === Object) {
          throw 'Key cannot be an object.';
        } else {
          storageValue = {};
          storageValue[newValue] = keyValue;
        }
      } else {
        storageValue = newValue;
      }
    }

    if (useCookie && storageIdentifier) {
      setCookie(storageIdentifier, storageValue, storageTTL);
    }
    if (useLocalStorage && storageIdentifier) {
      setLocalStorage(storageIdentifier, storageValue, storageTTL);
    }
    listeners.forEach(l => l && l(storageValue));
    return storageValue;
  }

  function reset() {
    storageValue = defaultStorageValue;

    if (useCookie && storageIdentifier) {
      setCookie(storageIdentifier, storageValue, storageTTL);
    }
    if (useLocalStorage && storageIdentifier) {
      setLocalStorage(storageIdentifier, storageValue, storageTTL);
    }
    listeners.forEach(l => l && l(storageValue));
    return storageValue;
  }

  function refresh() {
    listeners.forEach(l => l(storageValue));
    if (useCookie && storageIdentifier) {
      setCookie(storageIdentifier, storageValue, storageTTL);
    }
    if (useLocalStorage && storageIdentifier) {
      setLocalStorage(storageIdentifier, storageValue, storageTTL);
    }
    return storageValue;
  }

  function subscribe(listenerFunc) {
    var has_element = false;
    listeners.map(listener => {
      if (listener === listenerFunc) {
        has_element = true;
      }
    });
    if (!has_element) {
      listeners.push(listenerFunc);
    }
    return () => unsubscribe(listenerFunc); // will be used inside React.useEffect
  }

  function unsubscribe(listenerFunc) {
    listeners = listeners.filter(l => l !== listenerFunc);
  }

  function clearListeners() {
    listeners = [];
  }

  return {
    get,
    set,
    reset,
    refresh,
    subscribe,
    unsubscribe,
    clearListeners,
  };
}
