import React from 'react';
import {Form, FormGroup, Label, Input, Button} from "reactstrap";
import {bottomAlert} from "../../Notifications/ToastAlerts";
import { putCall } from "../../../Helpers/Api";
import { getUser } from "../../../Helpers/User";
import { config } from "../../../config";
import { setUser } from "../../../States/User";
import ProfilePhoto from "../../../assets/images/profile.png";


class Profile extends React.Component{
  constructor(props){
    super(props);
    this.state = getUser();
    // this.state.display_name = "";
    this.handleSubmitProfile = this.handleSubmitProfile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmitProfile = (e) => {
    e.preventDefault();
    if(!this.state.display_name.trim().length) {
      this.setState({display_name: ""});
      bottomAlert("Please fill in your name.", "warning");
      return;
    }

    putCall({
      url: config.url.USERS,
      path: this.state.id,
      data: this.state,
      onSuccess: (response) => {
        bottomAlert("Display name saved.", "success");
        setUser(this.state);
        if(this.props.onSuccess){this.props.onSuccess();}
      },
      onFailure: (error) => {
        bottomAlert("Your request could not be processed. " + ((error.response && error.response.data.detail) || error.toString()), "warning");
      }
    })
  }

  handleChange = (e) => {
    this.setState({ display_name: e.target.value })
  }
  render(){
    return ( 
      <div style={{minHeight:'300px'}}>
        <div className="profile-image"><img src={ProfilePhoto} alt="profile"/></div>
        <h2>Your profile</h2>
        <Form onSubmit={this.handleSubmitProfile}>
          <FormGroup>
            <Label for="display_name">Display Name</Label>
            <Input
              type="text"
              name="display_name"
              id="display_name"
              value={this.state.display_name}
              onChange={this.handleChange}
              placeholder="Your name in Meetings"/>
            <Button color="success" type="submit" className="mt-2">Save</Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default Profile;