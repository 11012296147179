import { createReduxModule } from "hooks-for-redux";

export const [role_id_permi_id_context, {setrole_id_permi_id_Context, resetrole_id_permi_id_Context}, role_id_permi_id_ContextStore] = createReduxModule(
  "role_id_permi_id_context",
  {},
  {
    setrole_id_permi_id_Context:(role_id_permi_id_context, role_id_permi_id_ContextObject) => {role_id_permi_id_context = role_id_permi_id_ContextObject; return role_id_permi_id_context},
    resetrole_id_permi_id_Context:(role_id_permi_id_context) => {role_id_permi_id_context = {}; return role_id_permi_id_context}
  }
);

export function getrole_id_permi_id_Context() {
  return role_id_permi_id_ContextStore.getState();
}