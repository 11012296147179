import React from 'react';
import CenterMessage from '../Loaders/CenterMessage';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default class ShowContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.props.toggle(!this.state.modal);
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    const closeBtn = (
      <button className="close" onClick={this.toggle}>
        &times;
      </button>
    );

    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
        {this.props.title && <ModalHeader toggle={this.toggle} close={closeBtn}>
          {this.props.title}
        </ModalHeader>}
        {this.props.content && <ModalBody>
          {this.props.content}
        </ModalBody>}
        <ModalFooter>
          {/* <Button color="link" onClick={this.toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={this.toggle}>
            Do Something
          </Button>{" "} */}
          {this.props.footer ? this.props.footer :
          <Button color="primary" onClick={this.toggle}>
            {this.props.buttonText || "Close"}
          </Button>
          }{" "}
        </ModalFooter>
      </Modal>
    );
  }
}