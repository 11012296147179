import { createReduxModule } from "hooks-for-redux";
import {auth} from '../Auth/Firebase';
import { bottomAlert } from "../Components/Notifications/ToastAlerts";
import { config } from "../config";
import { postCall } from "../Helpers/Api";
import { getCookie, hasCookie, setCookie } from "../Helpers/Cookie";
import { user } from "./User";

export const [token, {setAuthenticated, setkeycloak, setAuthUser, setToken, resetToken}, tokenStore] = createReduxModule(
  "token",
  {
    keycloak: null,
    isAuthenticated: false,
    user: null,
    token: {
      token: "",
      refresh_token: "",
      expire_at: 0
    }
  },
  {
    resetToken: (token) => {token.token = {token: "", refresh_token: "", expire_at: 0 }; return token},
    setToken: (token, token_object) => {token.token = token_object; return token},
    setAuthenticated: (token, auth_state) => {token.isAuthenticated = auth_state; return token},
    setkeycloak: (token, keycloak) => {token.keycloak = keycloak; return token},
    setAuthUser: (token, user) => {token.user = user; return token}
  }
);

export function isAuthenticated() {
  return tokenStore.getState()["isAuthenticated"];
}

export function getKeycloak() {
  return tokenStore.getState()["keycloak"];
}

export function getAuthUser() {
  return tokenStore.getState()["user"]
}

export function getToken() {
  return tokenStore.getState()["token"]
}

export function needLogin({onSuccess = () => {}, onFailure = () => {}}) {
  if(hasCookie("saleassist_auth_token")) {
    setToken(getCookie("saleassist_auth_token"));
    setAuthenticated(true);
    onSuccess(true);
    return;
  }

  auth.onAuthStateChanged(userAuth => {
    if (userAuth) {
      setAuthUser(userAuth);
      var token = Object.entries(userAuth)[5][1].b.g
      postCall({
        url: config.url.AUTH,
        data: {token: token},
        onSuccess: (response) => {
          setToken(response.data);
          setAuthenticated(true);
          // setCookie("saleassist_auth_token", response.data);
          onSuccess(true);
        },
        onFailure: (error) => {
          bottomAlert(error.toString(), "warning");
          setAuthUser(null);
          resetToken();
          setAuthenticated(false);
          onSuccess(false);    
        }
      });
    } else {
      setAuthUser(null);
      setToken("");
      setAuthenticated(false);
      onSuccess(false);
    }
  });
}
