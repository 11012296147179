import { getCall } from './Api';
import { config } from '../config';
import { setClient, resetClient } from '../States/Client';
import { bottomAlert } from '../Components/Notifications/ToastAlerts';
import { getUser } from './User';
import { getClient } from './Client';

export function planIsActive() {
  var client = getActiveClient();
  if(!client || !client.is_enabled || client.plan_status != "active"){
    return false;
  }

  return true;
}

export function getActiveClient() {
  if(getUser().active_client_id == getClient().id) {
    return getClient();
  } else {
    getCall({
      url: config.url.CLIENTS,
      path: getUser().active_client_id,
      onSuccess: (response) => {
        setClient(response.data);
      },
      onFailure: (error) => {
        resetClient();
        bottomAlert("Unable to load active organization. " + ((error.response && error.response.data.detail) || error.toString()));
      }
    });
  }
}

export function getRooms() {
  return getClient()["room_names"];
}

export function getUserRoom() {
  return getUser()["room_name"];
}
