import React, { Fragment } from "react";
import { withRouter } from "react-router";
import { Button } from "reactstrap";

import { VerticalTimelineElement } from "react-vertical-timeline-component";
import {
  timeFromNow,
  msToLongDateString,
  // msToDateString,
  msToMinuteSec,
} from "../../Helpers/Time";
import {
  startMeeting,
  endMeeting,
  getPublicShareUrl,
  getUserRole,
  getRegistrationFormUrl,
  getScheduleShareUrl,
} from "../../Helpers/Meetings";
import copy from "copy-to-clipboard";
import { getUser } from "../../Helpers/User";
import { underscoreToSpacedCamel } from "../../Helpers/Text";
import { getUserExperience } from "../../States/MeetingExperiences";
import { isAllowed } from "../../States/Permissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare, faPhoneSlash, faPhone, faEye, faInfoCircle } from "@manysale/pro-solid-svg-icons";
// import { Divider } from "@material-ui/core";
import { FaChevronCircleRight } from "react-icons/fa";
import "./timeline.scss"
import { Drawer, Paper } from "@mui/material";



class MeetingTimelineElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.meeting,
      openDrawer: false,
    };
    this.joinMeeting = this.joinMeeting.bind(this);
    this.startMeeting = this.startMeeting.bind(this);
    this.endMeeting = this.endMeeting.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.public_share_text = "";
    this.registration_form_link = "Copy registration url";
  }

  joinMeeting() {
    this.props.history.push("/workspace/meetings/live/" + this.state.id);
  }

  startMeeting() {
    startMeeting({
      meeting: this.state,
      onSuccess: () =>
        this.props.history.push("/workspace/meetings/live/" + this.state.id),
    });
  }

  endMeeting() {
    endMeeting({
      meeting: this.state,
      onSuccess: () => this.props.redraw(),
    });
  }

  toggleDrawer(event, open) {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ openDrawer: open })
  };

  isStartButtonEnabled() {
    const currentTime = new Date();
    const scheduledTime = new Date(this.state.scheduled_time);
    const timeDifference = (scheduledTime - currentTime) / (1000 * 60);
 
    return timeDifference <= 5;
}
  render() {
    const isButtonEnabled = this.isStartButtonEnabled();
    var status = "secondary";
    var buttons = <p></p>;
    var user_id = getUser().id;
    const screen_max_450 = window.matchMedia("(max-width: 450px)").matches;
    if (["SCHEDULED", "WAITING"].includes(this.state.meeting_status)) {
      status = "warning";
      buttons = (
        <p className="dashboard-meeting-details-content-flex">
          {(user_id == this.state.created_by_user_id ||
            getUserExperience(
              this.state.meeting_type,
              getUserRole({
                meeting: this.state,
                user_name: getUser().user_name,
              })
            ).can_start_meeting) && (
              <Button
                outline
                className="btn-wide mb-2"
                size="lg"
                color="success"
                onClick={(e) => {
                  if (!(!e.detail || e.detail == 1)) {
                    return;
                  }
                  this.startMeeting();
                }}
                style={{ pointerEvents: isButtonEnabled ? "auto" : "none", opacity: isButtonEnabled ? 1 : 0.5 }}
              >
                <i>
                  <FontAwesomeIcon icon={faPhone} />
                </i>
                Start Meeting
              </Button>
            )}
          {(user_id == this.state.created_by_user_id ||
            getUserExperience(
              this.state.meeting_type,
              getUserRole({
                meeting: this.state,
                user_name: getUser().user_name,
              })
            ).can_invite) && (
              <Button
                outline
                className="btn-wide mb-2"
                size="lg"
                color="secondary"
                onClick={(e) => {
                  this.public_share_text = " Copied";
                  this.setState({ ...this.state });
                  copy(getScheduleShareUrl({ meeting: this.state }));
                  setTimeout(() => {
                    this.public_share_text = "";
                    this.setState({ ...this.state });
                  }, 2000);
                }}
              >
                <i>
                  <FontAwesomeIcon icon={faShare} />
                </i>
                Share
                {this.public_share_text}
              </Button>
            )}
          {(user_id == this.state.created_by_user_id ||
            getUserExperience(
              this.state.meeting_type,
              getUserRole({
                meeting: this.state,
                user_name: getUser().user_name,
              })
            ).can_invite) &&
            this.state.registration_enabled && (
              <Button
                outline
                className="btn-wide mb-2"
                size="lg"
                color="secondary"
                onClick={(e) => {
                  this.registration_form_link = "Copied";
                  this.setState({ ...this.state });
                  copy(getRegistrationFormUrl({ meeting: this.state }));
                  setTimeout(() => {
                    this.registration_form_link = "Copy registration url";
                    this.setState({ ...this.state });
                  }, 2000);
                }}
              >
                {this.registration_form_link}
              </Button>
            )}
          {user_id == this.state.created_by_user_id ||
            getUserExperience(
              this.state.meeting_type,
              getUserRole({
                meeting: this.state,
                user_name: getUser().user_name,
              })
            ).can_start_meeting || (
              <Button
                outline
                className="btn-wide mb-2"
                size="lg"
                color="secondary"
                onClick={(e) => e.preventDefault()}
              >
                <i>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </i>
                Not started yet
              </Button>
            )}
        </p>
      );
    } else if (["STARTED", "IN_PROGRESS"].includes(this.state.meeting_status)) {
      status = "success";
      buttons = (
        <p className="dashboard-meeting-details-content-flex">
          <Button
            outline
            className="btn-wide mb-2"
            size="lg"
            color="info"
            onClick={(e) => {
              if (!(!e.detail || e.detail == 1)) {
                return;
              }
              this.joinMeeting();
            }}
          >
            <i>
              <FontAwesomeIcon icon={faPhone} />
            </i>
            Join Meeting
          </Button>
          {(user_id == this.state.created_by_user_id ||
            getUserExperience(
              this.state.meeting_type,
              getUserRole({
                meeting: this.state,
                user_name: getUser().user_name,
              })
            ).can_start_meeting) && (
              <Button
                outline
                className="btn-wide mb-2"
                size="lg"
                color="danger"
                onClick={(e) => {
                  if (!(!e.detail || e.detail == 1)) {
                    return;
                  }
                  this.endMeeting();
                }}
              >
                <i>
                  <FontAwesomeIcon icon={faPhoneSlash} />
                </i>
                End Meeting
              </Button>
            )}
          {(user_id == this.state.created_by_user_id ||
            getUserExperience(
              this.state.meeting_type,
              getUserRole({
                meeting: this.state,
                user_name: getUser().user_name,
              })
            ).can_invite) && (
              <Button
                outline
                className="btn-wide mb-2"
                size="lg"
                color="secondary"
                onClick={(e) => {
                  this.public_share_text = " Copied";
                  this.setState({ ...this.state });
                  copy(getPublicShareUrl({ meeting: this.state }));
                  setTimeout(() => {
                    this.public_share_text = "";
                    this.setState({ ...this.state });
                  }, 2000);
                }}
              >
                <i>
                  <FontAwesomeIcon icon={faShare} />
                </i>
                Share
                {this.public_share_text}
              </Button>
            )}
          {(user_id == this.state.created_by_user_id ||
            getUserExperience(
              this.state.meeting_type,
              getUserRole({
                meeting: this.state,
                user_name: getUser().user_name,
              })
            ).can_invite) &&
            this.state.registration_enabled && (
              <Button
                outline
                className="btn-wide mb-2"
                size="lg"
                color="secondary"
                onClick={(e) => {
                  this.registration_form_link = "Copied";
                  this.setState({ ...this.state });
                  copy(getRegistrationFormUrl({ meeting: this.state }));
                  setTimeout(() => {
                    this.registration_form_link = "Copy registration url";
                    this.setState({ ...this.state });
                  }, 2000);
                }}
              >
                {this.registration_form_link}
              </Button>
            )}
        </p>
      );
    } else if (["COMPLETED"].includes(this.state.meeting_status)) {
      status = "secondary";
      buttons = (
        <p className="dashboard-meeting-details-content-flex">
          {(user_id == this.state.created_by_user_id ||
            getUserExperience(
              this.state.meeting_type,
              getUserRole({
                meeting: this.state,
                user_name: getUser().user_name,
              })
            ).can_start_meeting) && (
              <Button
                outline
                className="btn-wide mb-2"
                size="lg"
                color="success"
                onClick={(e) => {
                  if (!(!e.detail || e.detail == 1)) {
                    return;
                  }
                  this.startMeeting();
                }}
              >
                <i>
                  <FontAwesomeIcon icon={faPhone} />
                </i>
                Start Again
              </Button>
            )}
          {(user_id == this.state.created_by_user_id ||
            getUserExperience(
              this.state.meeting_type,
              getUserRole({
                meeting: this.state,
                user_name: getUser().user_name,
              })
            ).can_invite) && (
              <Button
                outline
                className="btn-wide mb-2"
                size="lg"
                color="secondary"
                onClick={(e) => {
                  this.public_share_text = " Copied";
                  this.setState({ ...this.state });
                  copy(getPublicShareUrl({ meeting: this.state }));
                  setTimeout(() => {
                    this.public_share_text = "";
                    this.setState({ ...this.state });
                  }, 2000);
                }}
              >
                <i>
                  <FontAwesomeIcon icon={faShare} />
                </i>
                Share
                {this.public_share_text}
              </Button>
            )}
        </p>
      );
    } else if (["CANCELLED"].includes(this.state.meeting_status)) {
      status = "secondary";
      buttons = "";
    } else {
      status = "secondary";
      buttons = (
        <p className="dashboard-meeting-details-content-flex">
          <Button
            outline
            className="btn-wide mb-2"
            size="lg"
            active
            color="secondary"
            onClick={(e) => e.preventDefault()}
          >
            {this.state.meeting_status.toLowerCase()}
          </Button>
        </p>
      );
    }
    var date = timeFromNow(this.state.scheduled_time).strRepr;
    if (["CANCELLED"].includes(this.state.meeting_status)) {
      return (
        <VerticalTimelineElement
          className="vertical-timeline-item"
          icon={
            <i className={"badge badge-dot badge-dot-xl badge-" + status}> </i>
          }
          date={date}
        >
          <h4 className="timeline-title">{this.state.meeting_name}</h4>
          <p>{this.state.description}</p>
          {buttons}
        </VerticalTimelineElement>
      );
    } else {
      if (user_id == this.state.created_by_user_id || isAllowed("meetings")) {
        var meeting_name = this.state.meeting_name;
        if (this.state.meeting_type === "direct_call") {
          meeting_name =
            "Call to " +
            this.state.participants.join(", ").replace(/, ([^,]*)$/, " and $1");
        }
        return (
          <>
            {/* {this.props.previousDate !== this.props.meeting.date && (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    padding: "5px 20px",
                    color: "rgba(0,0,0,0.5)",
                    background: "#fff",
                    margin: "auto",
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  {msToDateString(this.props.meeting.scheduled_time)}
                </span>
                <Divider
                  style={{
                    position: "absolute",
                    width: "60%",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                />
              </div>
            )} */}
            <Paper
              className="dashboard-meeting-details-card"
              elevation={3}
              onClick={(e) => this.toggleDrawer(e, true)}
            >
              <div>
                <p className="dashboard-meeting-details-title">
                  {this.props.meeting.meeting_name}
                  <span>{timeFromNow(this.props.meeting.scheduled_time).strRepr}</span>
                </p>
              </div>
              <div
                className="dashboard-meeting-details-quick-action"
                style={{ display: "flex", placeItems: "center", gap: "10px" }}
              >
                <span
                  className={"dashboard-meeting-details-quick-action-btn"}
                  onClick={(e) => {
                    if (!(!e.detail || e.detail === 1)) {
                      return;
                    }
                    if (["SCHEDULED", "WAITING", "COMPLETED"].includes(this.state.meeting_status)) {
                      e.stopPropagation();
                      this.startMeeting();
                    }
                    if (["STARTED", "IN_PROGRESS"].includes(this.state.meeting_status)) {
                      this.joinMeeting();
                    }
                  }}
                  style={{ pointerEvents: isButtonEnabled ? "auto" : "none", opacity: isButtonEnabled ? 1 : 0.5 }}
                >
                  {["SCHEDULED", "WAITING"].includes(this.state.meeting_status)
                    ? "Start"
                    : ["STARTED", "IN_PROGRESS"].includes(this.state.meeting_status)
                      ? "Join"
                      : ["COMPLETED"].includes(this.state.meeting_status)
                        ? "Restart"
                        : ""}
                </span>
                <FaChevronCircleRight />
              </div>
            </Paper>
            <Drawer
              anchor={screen_max_450 ? "bottom" : "right"}
              open={this.state.openDrawer}
              onClose={(e) => this.toggleDrawer(e, false)}
              className="dashboard-meeting-details-drawer"
            >
              <div className="dashboard-meeting-details-content">
                <div className="dashboard-meeting-details-content-flex">
                  <h3 style={{ textTransform: "capitalize", marginBottom: "20px" }}>{this.props.meeting.meeting_name}</h3>
                  <p>
                    <strong>Schedule:</strong> {this.props.meeting.time}{" "}
                    {this.props.meeting.timezone}
                  </p>
                  {this.props.meeting.description.length > 0 && (
                    <p>
                      <strong>Description:</strong> {this.props.meeting.description}
                    </p>
                  )}
                  {this.props.meeting.source_name && (
                    <p>
                      <strong>Widget:</strong> {this.props.meeting.source_name} {this.props.meeting.source_name && <>({underscoreToSpacedCamel(this.props.meeting.source_type)})</>}
                    </p>
                  )}
                  {this.props.meeting.source_referrer && (
                    <p>
                      <strong>Source url:</strong> {this.props.meeting.source_referrer}
                    </p>
                  )}
                  <p className="dashboard-meeting-details-content-info">
                    <span>
                      {this.props.meeting.recordings.length > 0 && (
                        <span className="text-danger mr-2">Recorded.</span>
                      )}
                      {this.props.meeting.streamings.length > 0 && (
                        <span className="text-success mr-2">Streamed.</span>
                      )}
                      {this.props.meeting.participant_count > 0 && (
                        <span className="text-primary mr-2">
                          {this.props.meeting.participant_count}
                          {/* /
                          {
                            new Set(
                              this.props.meeting.moderators.concat(
                                this.props.meeting.participants
                              )
                            ).size
                          } */}
                          {" "}
                          Attended
                        </span>
                      )}
                    </span>
                    {this.props.meeting.public_session_count > 0 && (
                      <span className="text-primary mr-2">
                        Public sessions:{" "}
                        {this.props.meeting.public_session_count}
                      </span>
                    )}
                    {this.props.meeting.end_time > 0 && (
                      <span className="text-secondary mr-2">
                        Duration:{" "}
                        {msToMinuteSec(
                          this.props.meeting.end_time - this.props.meeting.start_time
                        )}
                      </span>
                    )}
                    {this.props.meeting.cumulative_participant_time > 0 && (
                      <span className="text-secondary mr-2">
                        Participant time:{" "}
                        {msToMinuteSec(
                          this.props.meeting.cumulative_participant_time
                        )}
                      </span>
                    )}
                  </p>
                </div>
                <div className="dashboard-meeting-details-content-flex">
                  {buttons}
                  <Button
                    outline
                    className="btn-wide mb-2"
                    size="lg"
                    color="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(
                        "/workspace/meetings/view/" + this.props.meeting.id
                      );
                    }}
                  >
                    <i>
                      <FontAwesomeIcon icon={faEye} />
                    </i>
                    View Details
                  </Button>
                </div>
              </div>
            </Drawer>

            {/*
            <VerticalTimelineElement
          className="vertical-timeline-item"
          icon={
            <i
              className={
                "badge badge-dot badge-dot-xl badge-" +
                (this.props.meeting.meeting_status == "IN_PROGRESS"
                  ? "warning"
                  : this.props.meeting.meeting_status == "SCHEDULED"
                  ? "primary"
                  : this.props.meeting.meeting_status == "COMPLETED"
                  ? "success"
                  : "secondary")
              }
            >
              {" "}
            </i>
          }
          date={timeFromNow(this.props.meeting.scheduled_time).strRepr}
        >
          <div
          >
            <h4 className="timeline-title text-primary"
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push(
                  "/workspace/meetings/view/" + this.props.meeting.id
                );
              }}
              style={{ cursor: "pointer" }}
            >
              {this.props.meeting.meeting_name} <FontAwesomeIcon icon={faEye} />
            </h4>
            <p>
              <strong>Schedule:</strong> {this.props.meeting.time}{" "}
              {this.props.meeting.timezone}
            </p>
            {this.props.meeting.description.length > 0 && (
              <p>
                <strong>Description:</strong> {this.props.meeting.description}
              </p>
            )}
            {this.props.meeting.source_name && (
              <p>
                <strong>Widget:</strong> {this.props.meeting.source_name} {this.props.meeting.source_name && <>({underscoreToSpacedCamel(this.props.meeting.source_type)})</>}
              </p>
            )}
            {this.props.meeting.source_referrer && (
              <p>
                <strong>Source url:</strong> {this.props.meeting.source_referrer}
              </p>
            )}
            <p>
              {this.props.meeting.recordings.length > 0 && (
                <span className="text-danger mr-2">Rec.</span>
              )}
              {this.props.meeting.streamings.length > 0 && (
                <span className="text-primary mr-2">Streamed.</span>
              )}
              {this.props.meeting.participant_count > 0 && (
                <span className="text-primary mr-2">
                  {this.props.meeting.participant_count}
*/}
            {/* /
                  {
                    new Set(
                      this.props.meeting.moderators.concat(
                        this.props.meeting.participants
                      )
                    ).size
                  } */}
            {/*
                  {" "}
                  Attended
                </span>
              )}
              {this.props.meeting.public_session_count > 0 && (
                <span className="text-primary mr-2">
                  {this.props.meeting.public_session_count} Public sessions
                </span>
              )}
              {this.props.meeting.end_time > 0 && (
                <span className="text-secondary mr-2">
                  Duration:{" "}
                  {msToMinuteSec(
                    this.props.meeting.end_time - this.props.meeting.start_time
                  )}
                </span>
              )}
              {this.props.meeting.cumulative_participant_time > 0 && (
                <span className="text-secondary mr-2">
                  Participant time:{" "}
                  {msToMinuteSec(
                    this.props.meeting.cumulative_participant_time
                  )}
                </span>
              )}
            </p>
          </div>
          {buttons}
        </VerticalTimelineElement>
*/}
          </>
        );
      } else {
        return (
          <VerticalTimelineElement
            className="vertical-timeline-item"
            icon={
              <i className={"badge badge-dot badge-dot-xl badge-" + status}>
                {" "}
              </i>
            }
            date={date}
          >
            <h4 className="timeline-title">{this.state.meeting_name}</h4>
            <p>{this.state.description}</p>
            <p>Scheduled at: {msToLongDateString(this.state.scheduled_time)}</p>
            {buttons}
          </VerticalTimelineElement>
        );
      }
    }
  }
}


export default withRouter(MeetingTimelineElement);
