import React, { useEffect, useState } from "react";
import AppHeader from "../../Layout/AppHeader";
import { getCall, postCall, putCall, deleteCall } from "../../Helpers/Api";
import {
  Row,
  Card,
  Col,
  Label,
  Form,
  Input,
  Button,
  FormGroup,
} from "reactstrap";
import { useParams } from "react-router-dom";
import "./whatsappnotification.scss";
import { DropdownList } from "react-widgets";
import { useHistory } from "react-router-dom";
import "./smartphone.scss";
import { bottomAlert } from "../../Components/Notifications/ToastAlerts";
import { Link } from "react-router-dom";
import ProcessingOverlay from "../../Components/Modal/ProcessingOverlay";
import { whatsAppDefaultConfig } from "../../Components/Forms/Settings/whatsAppDefaultConfig";
import { convertTime } from "../../Helpers/Time";

const WhatsAppNotifications = () => {
  const { actionName } = useParams();
  const history = useHistory();
  const [notificationData, setNotificationData] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [templateID, setTemplateID] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [action, setAction] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [deleteButton, setDeleteButton] = useState(false);
  const [variableInput, setVariableInput] = useState("");
  const [user, setUser] = useState("");
  const [type, setType] = useState("");
  const [inputDisabled, setInputDisabled] = useState(false);
  const [selectedTemplateData, setSelectedTemplateData] = useState([]);
  const [body, setBody] = useState(null);
  const [reminderTime, setReminderTime] = useState({});

  useEffect(() => {
    setIsDataLoading(true);
    getCall({
      path: `/notifications/template/${actionName}/whatsapp`,
      onSuccess: (response) => {
        // convert time from seconds to hours/days
        const reminderTimeConverted = {
          custom: response.data.reminder_time.custom,
          in_hours: convertTime(
            "secondsToHours",
            response.data.reminder_time.in_hours
          ),
          in_days: convertTime(
            "secondsToDays",
            response.data.reminder_time.in_days
          ),
          in_seconds: response.data.reminder_time.in_seconds,
        };

        setNotificationData(response.data);
        setAction(response.data.action);
        setBody(response.data.template_body);
        setSelectedTemplate(response.data.template_config.template_name);
        setTemplateID(response?.data?.template_config?.template_id);
        setUser(response.data.notification_user);
        setType(response.data.notification_type);
        setVariableInput(response.data.template_config.variables);
        setIsDataLoading(false);
        setReminderTime(reminderTimeConverted);
      },
      onFailure: (error) => {
        if (inputDisabled) {
          if (error.response && error.response.status === 404) {
            setDeleteButton(true);
            const defaultData = whatsAppDefaultConfig[actionName];
            if (defaultData) {
              setAction(defaultData.action);
              setSelectedTemplate(defaultData.template_config.template_name);
              setTemplateID(defaultData.template_config.template_id);
              setUser(defaultData.notification_user);
              setType(defaultData.notification_type);
              setBody(defaultData.template_body);
            } else {
              bottomAlert(
                "Default data not available for this action",
                "warning"
              );
            }
            setIsDataLoading(false);
          } else {
            bottomAlert(
              "Unable to load  data. " +
                (error.response
                  ? error.response.data.detail
                  : error.toString()),
              "warning"
            );
            setIsDataLoading(false);
          }
        } else {
          if (error.response && error.response.status === 404) {
            setDeleteButton(true);

            const defaultData = whatsAppDefaultConfig[actionName];
            if (defaultData) {
              setAction(defaultData.action);
              setUser(defaultData.notification_user);
              setType(defaultData.notification_type);
            } else {
              bottomAlert("Data not available for this action", "warning");
            }
            setIsDataLoading(false);
          } else {
            bottomAlert(
              "Unable to load data. " +
                (error.response
                  ? error.response.data.detail
                  : error.toString()),
              "warning"
            );
            setIsDataLoading(false);
          }
        }
      },
    });
    getCall({
      path: "/users/fetch_or_create",
      onSuccess: (response) => {
        if (
          response.data.client.whatsapp_account_id === "" &&
          response.data.client.whatsapp_business_id === "" &&
          response.data.client.whatsapp_number === ""
        ) {
          setInputDisabled(true);
          setIsDataLoading(false);
        } else {
          setInputDisabled(false);
          setIsDataLoading(false);
        }
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to load whatsapp profile data. " +
            (error.response ? error.response.data.detail : error.toString()),
          "warning"
        );
        setIsDataLoading(false);
      },
    });
    postCall({
      path: `whatsapp/sync_airtel_templates`,
      data: {},
      onSuccess: (response) => {
        setTemplateData(response.data.record);
        setIsDataLoading(false);
      },
      onFailure: () => {
        bottomAlert("Unable to load whatsapp template");
        setIsDataLoading(false);
      },
    });
  }, []);
  useEffect(() => {
    if (selectedTemplate) {
      const selectedTemplateData = templateData.find(
        (template) => template.templateName === selectedTemplate
      );

      if (selectedTemplateData) {
        setBody(selectedTemplateData.body);
      }
    }
  }, [selectedTemplate, templateData]);
  const handleWhatsAppTemplate = () => {
    setIsDataLoading(true);
    postCall({
      path: `whatsapp/sync_airtel_templates`,
      data: {},
      onSuccess: (response) => {
        setTemplateData(response.data.record);
        setIsDataLoading(false);
      },
      onFailure: () => {
        bottomAlert("Unable to load whatsapp template");
        setIsDataLoading(false);
      },
    });
  };
  const extractTemplateData = (templateName) => {
    return templateData.find(
      (template) => template.templateName === templateName
    );
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (templateID.trim() === "") {
      bottomAlert("Template ID is required", "warning");
      return;
    }
    const variablesArray = variableInput
      .split(",")
      .map((variable) => variable.trim());

    const variablesString = variablesArray.join(",");

    // convert time from hours/days to seconds
    const reminderTimeConverted = {
      custom: reminderTime.custom || 0,
      in_hours: convertTime("hoursToSeconds", reminderTime.in_hours),
      in_days: convertTime("daysToSeconds", reminderTime.in_days),
      in_seconds: +reminderTime.in_seconds,
    };

    putCall({
      path: `notifications/template/${actionName}/whatsapp`,
      data: {
        ...notificationData,
        action: action,
        template_config: {
          ...notificationData.template_config,
          template_name: selectedTemplate,
          template_id: templateID,
          variables: variablesString,
        },
        template_body: body,
        notification_user: user,
        notification_type: type,
        notification_channel: "whatsapp",
        reminder_time: reminderTimeConverted,
      },
      onSuccess: (response) => {
        bottomAlert("Successfully updated ", "success");
        history.push("/settings/tab/notifications");
      },
      onFailure: (error) => {
        if (error.response && error.response.status === 404) {
          const defaultData = whatsAppDefaultConfig[actionName];
          if (defaultData) {
            postCall({
              path: `notifications/template`,
              data: {
                action: action,
                template_config: {
                  meta: selectedTemplateData,

                  template_id: templateID,
                  template_name: selectedTemplate,
                  variables: variablesString,
                },
                notification_user: user,
                notification_type: type,
                template_body: body,
                notification_channel: "whatsapp",
              },
              onSuccess: (response) => {
                bottomAlert("Successfully updated ", "success");
                history.push("/settings/tab/notifications");
              },
              onFailure: (error) => {
                if (
                  error.response &&
                  error.response.status === 400 &&
                  error.response.data.detail
                ) {
                  bottomAlert(error.response.data.detail, "warning");
                }
              },
            });
          } else {
            bottomAlert(
              "We were unable to update your user. " +
                ((error.response && error.response.data.detail) ||
                  error.toString()),
              "warning"
            );
          }
        } else if (
          error.response &&
          error.response.status === 400 &&
          error.response.data.detail
        ) {
          bottomAlert(error.response.data.detail, "warning");
        }
      },
    });
  };
  const handleDelete = (e) => {
    e.preventDefault();
    deleteCall({
      path: `notifications/template/${actionName}/whatsapp`,
      onSuccess: (response) => {
        bottomAlert("Successfully removed", "success");
        history.push("/settings/tab/notifications");
      },

      onFailure: (error) => {
        bottomAlert(
          "Unable to remove. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
      },
    });
  };

  return (
    <React.Fragment>
      <AppHeader />
      <div className="app-main" style={{ marginTop: "0" }}>
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Row>
              <Col md={8}>
                <Card>
                  <Form>
                    <div className="w-100 p-4">
                      <FormGroup>
                        <Label>Action</Label>
                        <DropdownList
                          placeholder="Select Action"
                          dataKey="id"
                          textField="name"
                          value={action}
                          data={[
                            "create_meeting_video_call_agent",
                            "create_meeting_video_call_customer",
                            "create_meeting_schedule_call_member",
                            "create_meeting_schedule_call_agent",
                            "create_meeting_schedule_call_customer",
                            "create_meeting_schedule_call_agent",
                          ]}
                          disabled
                          readOnly
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>User</Label>
                        <DropdownList
                          placeholder="Select User"
                          dataKey="notification_user"
                          value={user}
                          data={["agent", "customer"]}
                          onChange={(value) => setUser(value)}
                          disabled
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Type</Label>
                        <DropdownList
                          placeholder="Select Type"
                          dataKey="notification_type"
                          value={type}
                          data={["inbound", "outbound"]}
                          onChange={(value) => setType(value)}
                          disabled
                        />
                      </FormGroup>
                      <FormGroup className="mt-1">
                        <Label className="mt-1">Template</Label>
                        <div className="flex " style={{ gap: "8px" }}>
                          <DropdownList
                            placeholder="Select Template"
                            dataKey="id"
                            textField="name"
                            value={selectedTemplate}
                            data={[
                              ...templateData.map((template) => ({
                                id: template.templateName,
                                name: template.templateName,
                              })),
                            ]}
                            className="w-100"
                            onChange={(value) => {
                              // Extract and use selected template data
                              const selectedTemplateData = extractTemplateData(
                                value.name
                              );

                              if (selectedTemplateData) {
                                // Set selected template and body
                                setSelectedTemplate(value.name);
                                setSelectedTemplateData(selectedTemplateData);
                                setTemplateID(selectedTemplateData.templateId);
                              }
                            }}
                            disabled={inputDisabled}
                          />
                          <Button
                            size="sm"
                            onClick={() => {
                              handleWhatsAppTemplate();
                            }}
                            color="success"
                          >
                            Refresh
                          </Button>
                        </div>
                      </FormGroup>
                      <FormGroup className="mt-1">
                        <Label className="mt-1">Template ID</Label>
                        <Input
                          id="templateID"
                          value={templateID}
                          onChange={(e) => setTemplateID(e.target.value)}
                          disabled
                        />
                      </FormGroup>

                      <Label for="header" className="mt-2">
                        Reminder times
                      </Label>
                      <div>
                        <FormGroup>
                          <div className="flex flex-row">
                            <Label
                              for="reminderDays"
                              className="notification-reminder-label"
                            >
                              Send reminder # of days before scheduled meeting
                            </Label>
                          </div>
                          <Input
                            type="text"
                            id="reminderDays"
                            className="w-25"
                            value={reminderTime.in_days}
                            onChange={(e) => {
                              const value = e.target.value;
                              setReminderTime((prev) => ({
                                ...prev,
                                in_days: value,
                              }));
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <div className="flex flex-row">
                            <Label
                              for="reminderHours"
                              className="notification-reminder-label"
                            >
                              Send reminder # of hours before scheduled meeting
                            </Label>
                          </div>
                          <Input
                            type="text"
                            id="reminderHours"
                            className="w-25"
                            value={reminderTime.in_hours}
                            onChange={(e) => {
                              const value = e.target.value;
                              setReminderTime((prev) => ({
                                ...prev,
                                in_hours: value,
                              }));
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <div className="flex flex-row">
                            <Label
                              for="reminderSeconds"
                              className="notification-reminder-label"
                            >
                              Send reminder # of seconds before scheduled
                              meeting
                            </Label>
                          </div>
                          <Input
                            type="text"
                            id="reminderSeconds"
                            className="w-25"
                            value={reminderTime.in_seconds}
                            onChange={(e) => {
                              const value = e.target.value;
                              setReminderTime((prev) => ({
                                ...prev,
                                in_seconds: value,
                              }));
                            }}
                          />
                        </FormGroup>
                      </div>

                      <FormGroup className="mt-1">
                        <Label className="mt-1">Body</Label>
                        <Input
                          type="textarea"
                          id="msg"
                          value={
                            templateData.find(
                              (template) =>
                                template.templateName === selectedTemplate
                            )?.body || body
                          }
                          // value={body}
                          onChange={(e) => setBody(e.target.value)}
                          disabled
                          readOnly={true}
                        />
                      </FormGroup>
                      <FormGroup className="mt-1">
                        <Label className="mt-1">
                          Variables(
                          <span>
                            Add {","} to add a variable.For example:
                            {"test1,test2"}
                          </span>
                          )
                        </Label>
                        <Input
                          type="text"
                          value={variableInput}
                          onChange={(e) => setVariableInput(e.target.value)}
                          disabled={inputDisabled}
                          placeholder="Enter comma-separated variables"
                        />
                      </FormGroup>

                      <div className="flex justify-between mt-2 ">
                        <div>
                          <Button
                            color="primary"
                            disabled={inputDisabled}
                            onClick={handleUpdate}
                          >
                            Update
                          </Button>
                          {!deleteButton && (
                            <Button
                              className="ml-2"
                              color="danger"
                              onClick={handleDelete}
                            >
                              Delete
                            </Button>
                          )}
                        </div>
                        {inputDisabled ? (
                          <p className="items-center">
                            Please Update the WhatsApp details first-
                            <Link to={"/settings/tab/organisation"}>
                              WhatsApp Profile
                            </Link>
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </Form>
                </Card>
              </Col>
              <Col md={4}>
                <div className="smartphone" style={{ marginTop: "15px" }}>
                  <div className="content">
                    <pre className="whatsappMessageStyle">
                      {templateData.find(
                        (template) => template.templateName === selectedTemplate
                      )?.body || body}
                    </pre>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <ProcessingOverlay show={isDataLoading} />
    </React.Fragment>
  );
};

export default WhatsAppNotifications;
